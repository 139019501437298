export class RecommendedLocation {
    constructor(json) {
        this.id = json._id;
        this.name = json.name;
        this.viewport = new Bounds(json.viewport);
        this.bounds = new Bounds(json.bounds)
        this.q = this.name;
    }
}

export class Bounds {


    constructor(json) {
        this.Southwest = json != null ? json['southwest'] : null;
        this.Northeast = json != null ? json['northeast'] : null;
    }
}