<template>
  <div>
    <div>
      <div class="md-layout md-gutter">
        <div
            class="md-layout-item md-size-100 user-text-container"
        >
          <div class="text-container">
            <div class="blockquote large-paragraph text-left">
              <small>
                {{ report.currentDateString }} <strong> • </strong> by: {{ reportedBy }}
              </small>
            </div>
            <div class="text-left">
              <h3 class="title">
                  <span class="tim-note">{{ report.reportOption.name }}</span>
              </h3>
            </div>
          </div>

        </div>
      </div>
    </div>
    <StoryspotCell :storyspot="report.reported" :toSpotBasePath="'/storyspots/'"/>
    <li class="dropdown-divider"></li>
  </div>
</template>
<script>
import Badge from "../Badge";
import Vue from "vue";
import QrCode from "../QrCode";
import sharedConfig from "../../shared-config"
import {copyString} from "../../utils/general-utils";
import StoryspotCell from "./StoryspotCell";

export default {
  components: {
    StoryspotCell,
    QrCode,
    Badge
  },
  props: {
    report: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      searchInput: null,
      showQrModal: false,
    }
  },
  computed: {
    reportedBy() {
      if (this.report.reportedBy.fullName) {
        return this.report.reportedBy.fullName;
      } else if (this.report.reportedBy.username) {
        return this.report.reportedBy.username;
      } else {
        return 'Gäst'
      }
    }
  },
  methods: {
    showModal() {
      this.showQrModal = true;
    },
    closeModal() {
      this.showQrModal = false;
    },
    copyLink() {
      let link = "https://app.storyspot.se/share/s" + this.storyspot.id;
      copyString(link);
      const instance = Vue.$toast.open({
        message: "Link copied successfully",
        type: "success",
        position: "top-right"
      });
    },
  }
};
</script>
<style lang="scss" scoped>

.wrapper {
  margin-top: 28px;
}

.blockquote {
  font-size: 18px;
}

.blockquote strong {
  margin: 0 5px 0 5px;
}

.text-container {
  float: left;
  margin-top: 0;
  padding: 0;
}

.text-container .p {
  text-overflow: ellipsis;
}

.title {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  color: #3C4858 !important;
}

.md-theme-default a:not(.md-button) {
  color: unset !important;
}


.center {
  margin: auto;
  width: 50%;
}

.icon {
  font-size: 18px !important;
}

.text-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.play-icon {
  position: absolute !important;
  right: 30px;
}


.dropdown-item:hover {
  .md-icon {
    color: white !important;
  }

  p {
    color: white !important;
  }
}

.md-tooltip {
  border-radius: unset;
  color: white !important;
  background-color: #989898 !important;
  font-weight: unset;
  box-shadow: unset;
  max-width: unset;
}

.not-active {
  float: left;
  padding-right: 8px
}

.displayImage {
  object-fit: contain !important;
  // Just to fill width
  width: 100px;
}

.no-profile-pic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.no-profile-pic {
  font-size: 68px !important;
  opacity: 0.5;
}

.md-list-item a .material-icons, .md-list-item a .md-icon, .md-list-item a .fab, .md-list-item a .fa {
  font-size: 25px !important;
}

.mini-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  p {
    padding-top: 10px;
  }
}

.user-text-container {
  min-height: 100px;

}

</style>