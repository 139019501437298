<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main main-raised">
        <BreadCrumbs :crumbs="breadcrumbs"/>
        <div class="section">
          <div v-if="!hasLoaded" class="loader md-layout md-gutter md-alignment-center-center">
            <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
          </div>
          <div v-if="hasLoaded" class="container">
            <SearchBar class="top-padding" @send-searchparameter="didChangeSearch" :title="'All recommended Locations'"
                       :storyspotCount="'(' + getLocations.length + ')'"  :filter="false"/>
            <div  class="md-layout">
              <div class="md-layout-item md-size-100 text-center">
                <div v-for="loc in pageOfItems">
                  <RecommendedLocation :location="loc"/>
                </div>
              </div>
            </div>
            <div class="text-right">
              <jw-pagination :items="getLocations" @changePage="onChangePage" :pageSize="25"
                             :labels="customLabels"></jw-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RecommendedLocation from '../../components/cells/RecommendedLocation.cell'
import SearchBar from '../../../../shared/components/SearchBar'
import BreadCrumbs from "../../../../shared/components/Breadcrumb";

export default {
  bodyClass: "Org-users",
  components: {
    RecommendedLocation,
    BreadCrumbs,
    SearchBar
  },
  created() {
    document.title = 'Recommendations explore - Storyspot';
  },
  data() {
    return {
      searchText: null,
      pageOfItems: [],
      customLabels: {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
      },
      breadcrumbs: [
        {
          to: '/',
          name: "Dashboard"
        },
      ]
    }
  },
  methods: {
    didChangeSearch(value) {
      this.searchText = value;
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    }
  },
  computed: {
    getLocations() {
      if (this.searchText) {
        return this.$store.getters.getRecommendedLocations.filter((a) => {
          return a.q.includes(this.searchText.toLowerCase());
        })
      }
      return this.$store.getters.getRecommendedLocations
    },
    hasLoaded() {
      return this.$store.getters.hasLoadedRecommendations;
    }
  }
}
</script>
<style>
.header {
  margin-bottom: 44px;
}
.main {
  min-height: 40vh;
  z-index: 0;
}
.section {
  padding-top: 20px !important;
}
</style>