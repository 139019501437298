import {convertToDateString} from "../utils/general-utils";
import {Storyspot} from "./Storyspot.model";

export class Report {

    constructor(json) {
        this.id = json.id;
        this.reportedBy = json.reportedBy;
        this._reported = json.reported;
        this.type = json.type;
        this.reportOption = json.reportOption;
        this.actionTaken = JSON.parse(json.actionTaken);
        this.createdAt = json.createdAt;
        this.updatedAt = json.updatedAt;
        this.version = json.version;
    }

    get currentDateString() {
        return convertToDateString(this.createdAt);
    }

    get reported() {
        if (this.type === 'storyspot') {
            return new Storyspot(this._reported);
        } else {
            return this._reported;
        }
    }
}

