<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main main-raised">
        <div class="section">
          <div v-if="isLoading" class="loader md-layout md-gutter md-alignment-center-center">
            <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
          </div>
          <div v-if="!isLoading" class="container">
            <SearchBar class="top-padding" @send-searchparameter="didChangeSearch" :title="'All storyspots'"
                       :storyspotCount="'(' + getStoryspots.length + ')'"  :filter="true"/>
            <div v-for="story in pageOfItems" v-bind:key="story.id">
              <StoryspotSmall :storyspot="story" :toSpotBasePath="'/storyspots/'"/>
            </div>
            <div class="text-right">
              <jw-pagination :items="getStoryspots" @changePage="onChangePage" :pageSize="25"
                             :labels="customLabels"></jw-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from "../../../shared/components/SearchBar";
import StoryspotSmall from "../../../shared/components/cells/StoryspotCell";
import StoryspotMap from "../../../shared/components/StoryspotMap";
import sharedConfig from "../../../shared/shared-config";

export default {
  bodyClass: "My-storyspots",
  components: {
    StoryspotSmall,
    SearchBar,
    StoryspotMap
  },
  created() {
    document.title = "All Storyspots - Storyspot";
  },
  mounted() {
    if (!this.$store.getters.hasLoadedStoryspots) {
      this.$store.dispatch(sharedConfig.store.actions.ADMIN.GET_ALL_STORYSPOTS_REQUEST);
    }
  },
  data() {
    return {
      searchText: null,
      pageOfItems: [],
      customLabels: {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
      },
    };
  },
  methods: {
    didChangeSearch(value) {
      this.searchText = value;
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    }
  },
  computed: {
    getStoryspots() {
      if (this.searchText) {
        return this.$store.getters.getStoryspots.filter(
            (a) =>
                a.q && a.q.indexOf(this.searchText.toLowerCase()) > -1
        )
      }
      return this.$store.getters.getStoryspots
    },
    isLoading() {
      return this.$store.getters.isLoadingStoryspots;
    }
  }
};
</script>

<style lang="scss" scoped>
.top-padding {
  margin-top: 25px;
}
.section {
  // Used cause we want the create storyspot and search header to act as section "headers"
  padding-top: 20px !important;
}

.main {
  min-height: 80vh;
  z-index: 0;
}

.button-container {
  margin-left: auto;
  margin-right: 0;
}
</style>
