<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main main-raised">
        <div class="section">
          <div class="container">
            <div class="header">
              <md-icon class="line">dashboard</md-icon>
              <h1 class="line">Dashboard</h1>
              <h3 class="line">Administration portal</h3>
            </div>
            <div class="header">
              <md-icon class="line">bar_chart</md-icon>
              <h4 class="line">Statistics for last {{ diffDays }} days</h4>
            </div>
            <div v-if="!hasLoaded" class="loader md-layout md-gutter md-alignment-center-center">
              <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
            </div>
            <div v-if="hasLoaded">
              <div class="md-layout">
                <div class="md-layout-item md-medium-50 md-large-20 md-small-100 md-xsmall-size-100">
                  <NumberCard :headline="'User registered'"  :number="usersCreatedDuringPeriod"/>
                </div>
                <div class="md-layout-item md-medium-50 md-large-20 md-small-100 md-xsmall-size-100">
                  <NumberCard :headline="'Storyspots created'" :number="storyspotsCreatedDuringPeriod"/>
                </div>
                <div class="md-layout-item md-medium-50 md-large-20 md-small-100 md-xsmall-size-100">
                  <NumberCard :headline="'Sessions'" :subheadline="'App starts'" :number="dashboardStats.getTotalSessions"/>
                </div>
                <div class="md-layout-item md-medium-50 md-large-20 md-small-100 md-xsmall-size-100">
                  <NumberCard :headline="'Active users'" :subheadline="'Uniq users'" :number="dashboardStats.getActiveUsers"/>
                </div>
                <div class="md-layout-item md-medium-50 md-large-20 md-small-100 md-xsmall-size-100">
                  <NumberCard :headline="'Sessions per user'" :subheadline="'Sessions/user'" :number="sessionsPerUser"/>
                </div>
                <div class="md-layout-item md-medium-50 md-large-50 md-small-100 md-xsmall-size-100">
                  <NumberCard :headline="'TOTAL registered users'" :subheadline="'All time'"
                              :number="dashboardStats.getNumberOfUsers"/>
                </div>
                <div class="md-layout-item md-medium-50 md-large-50 md-small-100 md-xsmall-size-100">
                  <NumberCard :headline="'TOTAL storyspots'" :subheadline="'All time'"
                              :number="dashboardStats.getNumberOfStoryspots"/>
                </div>
              </div>
              <div class="md-layout">
                <LineChart class="stats-over-time" :chartData="combinedOverTime.graph"
                           :headline="'User & storyspots created'"></LineChart>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-50 md-small-size-100">
                  <PieChart class="stats-over-time" :label="'By device'"
                            :chartData="dashboardStats.deviceChart"></PieChart>
                </div>
                <div class="md-layout-item md-size-50 md-small-size-100">
                  <BarChart class="stats-over-time" :label="'By top 20 cities'"
                            :chartData="dashboardStats.cityChart"></BarChart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import LineChart from "../components/analytics/LineChart";
import PieChart from "../components/analytics/PieChart";
import Summary from "../components/analytics/SummaryByMonth";
import BarChart from "../components/analytics/BarChart";
import NumberCard from "../../../shared/components/NumberCard";
import {AdminDashboard} from "../models/AdminDashboard.model";
import sharedConfig from "../../../shared/shared-config";


export default {
  bodyClass: "dashboard",
  components: {
    LineChart,
    PieChart,
    Summary,
    BarChart,
    NumberCard
  },
  created() {
    document.title = 'Admin dashboard - Storyspot';
  },
  data() {
    return {
      isLoading: null,
    }
  },
  methods: {},
  mounted() {
    if (!this.$store.getters.hasLoadedStats) {
      this.$store.dispatch(sharedConfig.store.analytics.GET_STATS, this.settings.fromDate, this.settings.toDate);
      this.isLoading = true;
    }
  },
  computed: {
    diffDays() {
      return this.$store.getters.diff;
    },
    settings() {
      return this.$store.getters.getDashboardDays;
    },
    dashboardStats() {
      return new AdminDashboard(this.$store.getters.dashboardStats, this.$store.getters.getUsers, this.$store.getters.getStoryspots)
    },
    combinedOverTime() {
      return this.dashboardStats.getUserAndStoryspotsOverTime(this.settings.fromDate, this.settings.toDate);
    },
    storyspotsCreatedDuringPeriod() {
      return this.dashboardStats.getCreatedStoryspotsFromPeriod(this.settings.fromDate, this.settings.toDate);
    },
    sessionsPerUser() {
      return (this.dashboardStats.getTotalSessions / this.dashboardStats.getActiveUsers).toFixed(2);
    },
    usersCreatedDuringPeriod() {
      return this.dashboardStats.getRegisteredUsersFromPeriod(this.settings.fromDate, this.settings.toDate);
    },
    hasLoaded() {
      return this.$store.getters.hasLoadedStoryspots && this.$store.getters.hasLoadedUsers && this.$store.getters.hasLoadedStats;
    }
  }
}
</script>

<style scoped>

.section {
  min-height: 80vh !important;

}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto 25px auto 0;
  width: fit-content;
}

.header .line:first-child {
  margin: 0 3px 0 0;
}

.header .line:not(:first-child) {
  margin: 0 10px 0 0;
}

a {
  cursor: pointer;
}

.stats-over-time {
  margin-top: 50px;
}

.md-datepicker {
  max-width: 145px;
}


</style>