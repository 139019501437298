import {getEventsOverTimeByDay, adminGraphColors} from "../../../shared/utils/stat-util";

export class AdminDashboard {

    constructor(stats, users, storyspots) {
        this.activityUsers = parseInt(stats.activityUsers)
        this.usersByCity = stats.activityByCity;
        this.usersByDevice = stats.activityByDevice;
        this.totalSessions = parseInt(stats.sessionsTotal);
        this.sessions = stats.sessionsOverTime;
        this._users = users;
        this._storyspots = storyspots;
    }

    get cityChart() {
        return {
            labels: this.usersByCity.slice(0,20).map((a) => a.city),
            datasets: [
                {
                    label: '',
                    backgroundColor: adminGraphColors,
                    data: this.usersByCity.slice(0,20).map((a) => a.value)
                }],
        }
    }

    get deviceChart() {
        return {
            labels: this.usersByDevice.map((a) => a.device),
            datasets: [
                {
                    label: '',
                    backgroundColor: adminGraphColors,
                    data: this.usersByDevice.map((a) => a.value)
                }],
        }
    }

    get getNumberOfUsers() {
        return this._users.length;
    }

    get getNumberOfStoryspots() {
        return this._storyspots.length;
    }

    getRegisteredUsersFromPeriod(fromDate, toDate) {
        return this._users.filter((a) => a.createdAt > fromDate && a.createdAt < toDate).length;
    }

    getCreatedStoryspotsFromPeriod(fromDate, toDate) {
        return this._storyspots.filter((a) => a.createdAt > fromDate && a.createdAt < toDate).length;
    }

    getUsersOverTime(fromDate, toDate) {
        let usersPeriod = this._users.filter((a) => a.createdAt > fromDate && a.createdAt < toDate);
        let overTime = getEventsOverTimeByDay(usersPeriod, fromDate, toDate, 'createdAt');
        let labels = overTime.keys.map((a) => {
            const dateSet = a.toDateString().split(' ');
            return `${a.toLocaleString('en-us', {month: 'long'})} ${dateSet[2]}`;
        });
        return {
            value: overTime,
            graph: {
                labels: labels,
                datasets: [
                    {
                        label: 'User registrations',
                        borderWidth: 2.5,
                        borderRadius: 8,
                        backgroundColor: adminGraphColors[0],
                        borderColor: adminGraphColors[0],
                        pointStyle: 'circle',
                        pointRadius: 2,
                        pointHoverRadius: 12,
                        pointHitRadius: 12,
                        tension: 0.4,
                        data: overTime.values,
                    }
                ]
            }
        }
    }

    getStoryspots(fromDate, toDate) {
        let storyspotPeriod = this._storyspots.filter((a) => a.createdAt > fromDate && a.createdAt < toDate);
        let overTime = getEventsOverTimeByDay(storyspotPeriod, fromDate, toDate, 'createdAt');
        let labels = overTime.keys.map((a) => {
            const dateSet = a.toDateString().split(' ');
            return `${a.toLocaleString('en-us', {month: 'long'})} ${dateSet[2]}`;
        });
        return {
            value: overTime,
            graph: {
                labels: labels,
                datasets: [
                    {
                        label: 'Storyspots created',
                        borderWidth: 2.5,
                        borderRadius: 8,
                        backgroundColor: adminGraphColors[1],
                        borderColor: adminGraphColors[1],
                        pointStyle: 'circle',
                        pointRadius: 2,
                        pointHoverRadius: 12,
                        pointHitRadius: 12,
                        tension: 0.4,
                        data: overTime.values,
                    }
                ]
            }
        }
    }

    getUserAndStoryspotsOverTime(fromDate, toDate) {
        let storyspotsOverTime = this.getStoryspots(fromDate, toDate);
        let usersOverTime = this.getUsersOverTime(fromDate, toDate);
        return {
            value: null,
            graph: {
                // They are the same so only need one set of labels
                labels: usersOverTime.graph.labels,
                datasets: [...usersOverTime.graph.datasets, ...storyspotsOverTime.graph.datasets]
            }
        }
    }

    get getTotalSessions() {
        return this.totalSessions;
    }

    get getActiveUsers() {
        return this.activityUsers;
    }
}