<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main main-raised">
        <BreadCrumbs :crumbs="breadcrumbs"/>
        <div class="section">
          <div v-if="!hasLoaded" class="loader md-layout md-gutter md-alignment-center-center">
            <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
          </div>
          <div v-if="hasLoaded" class="container">
            <SearchBar class="top-padding" @send-searchparameter="didChangeSearch" :title="'New reports'"
                       :storyspotCount="'(' + getReports.length + ')'"  :filter="false"/>
            <div  class="md-layout">
              <div class="md-layout-item md-size-100">
                <div v-for="report in pageOfItems">
                  <ReportCell :report="report"/>
                </div>
              </div>
            </div>
            <div class="text-right">
              <jw-pagination :items="getReports" @changePage="onChangePage" :pageSize="25"
                             :labels="customLabels"></jw-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReportCell from '../../../../shared/components/cells/ReportCell'
import SearchBar from '../../../../shared/components/SearchBar'
import BreadCrumbs from "../../../../shared/components/Breadcrumb";
import sharedConfig from "../../../../shared/shared-config";

export default {
  bodyClass: "Report-list",
  components: {
    ReportCell,
    BreadCrumbs,
    SearchBar
  },
  created() {
    document.title = 'Reported - Storyspot';
  },
  data() {
    return {
      searchText: null,
      pageOfItems: [],
      customLabels: {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
      },
      breadcrumbs: [
        {
          to: '/',
          name: "Dashboard"
        },
      ]
    }
  },
  mounted() {
    if (!this.$store.getters.hasLoadedReports) {
      this.$store.dispatch(sharedConfig.store.actions.ADMIN.GET_REPORTS_REQUEST);
    }
  },
  methods: {
    didChangeSearch(value) {
      this.searchText = value;
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    }
  },
  computed: {
    getReports() {
      if (this.searchText) {
        return this.$store.getters.getReports.filter((a) => !a.actionTaken && a.includes(this.searchText))
      }
      return this.$store.getters.getReports.filter((a) => !a.actionTaken);
    },
    getReportsOld() {
      if (this.searchText) {
        return this.$store.getters.getReports.filter((a) => {
          return !a.actionTaken
        })
      }
      return this.$store.getters.getReports
    },
    hasLoaded() {
      return this.$store.getters.hasLoadedReports;
    }
  }
}
</script>
<style>
.header {
  margin-bottom: 44px;
}
.main {
  min-height: 80vh;
  z-index: 0;
}
.section {
  padding-top: 20px !important;
}
</style>