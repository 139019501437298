import sharedConfig from "../../../shared/shared-config";
import storyspotService from "../../../shared/services/storyspot-service"
import {Storyspot} from "../../../shared/models/Storyspot.model";
import baseAPI from "../../../shared/services/base-api-service"
import {storyspotSort} from "../../../shared/utils/sort";

const state = {
    hasLoadedStoryspots: false,
    isLoadingStoryspots: false,
    storyspots: [],
}

const getters = {
    getStoryspots(state) {
        return state.storyspots;
    },
    hasLoadedStoryspots(state) {
        return state.hasLoadedStoryspots;
    },
    isLoadingStoryspots(state) {
        return state.isLoadingStoryspots;
    }
}

const actions = {
    [sharedConfig.store.actions.STORYSPOT.SET_SORT]: ({commit, dispatch}, sortValue) => {
        commit(sharedConfig.store.actions.STORYSPOT.SET_SORT, sortValue);
    },
    [sharedConfig.store.actions.ADMIN.GET_ALL_STORYSPOTS_REQUEST]: ({commit, dispatch}) => {
        commit(sharedConfig.store.actions.ADMIN.GET_ALL_STORYSPOTS_REQUEST);
        return new Promise(async (resolve, reject) => {
            baseAPI
                .getData(`${sharedConfig.api.storyspotEndpoint}`)
                .then(resp => {
                    commit(sharedConfig.store.actions.ADMIN.GET_ALL_STORYSPOTS_SUCCESS, resp.data);
                    resolve();
                })
                .catch(e => {
                    commit(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_ERROR, e);
                    reject(e);
                });

        });
    },
    [sharedConfig.store.actions.STORYSPOT.GET_STORYSPOT_DETAILED_REQUEST]: (
        { commit, dispatch },
        id
    ) => {
        return new Promise((resolve, reject) => {
            storyspotService
                .getStoryspotPlaceById(id)
                .then(sp => {
                    resolve(sp);
                })
                .catch(e => {
                    reject(e);
                });
        });
    },
    [sharedConfig.store.actions.STORYSPOT.GET_STORYSPOT_DETAILED_REQUEST]: (
        { commit, dispatch },
        id
    ) => {
        return new Promise((resolve, reject) => {
            storyspotService
                .getStoryspotPlaceById(id)
                .then(sp => {
                    resolve(sp);
                })
                .catch(e => {
                    reject(e);
                });
        });
    }
}

const mutations = {
    [sharedConfig.store.actions.STORYSPOT.SET_SORT]: (state, sortValue) => {
        state.storyspots = state.storyspots.sort(storyspotSort(sortValue));
    },
    [sharedConfig.store.actions.ADMIN.GET_ALL_STORYSPOTS_REQUEST]: (state, error) => {
        // TODO. manage error response
        state.isLoadingStoryspots = true;
    },
    [sharedConfig.store.actions.ADMIN.GET_ALL_STORYSPOTS_SUCCESS]: (state, storyspotsData) => {
        state.storyspots = storyspotsData
            .map((jsonData) => new Storyspot(jsonData))
            .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
            .sort((a,b) => (a.status.name < b.status.name ? -1 : 1));
        state.hasLoadedStoryspots = true;
        state.isLoadingStoryspots = false;
    },
    [sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_ERROR]: (state, error) => {
        // TODO. manage error response
        state.isLoadingStoryspots = false;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};