<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main main-raised">
        <BreadCrumbs :crumbs="breadcrumbs"/>
        <div class="section">
          <div v-if="!hasLoaded" class="loader md-layout md-gutter md-alignment-center-center">
            <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
          </div>
          <div v-if="hasLoaded" class="container">
            <SearchBar class="top-padding" @send-searchparameter="didChangeSearch" :title="'All Languages'"
                       :storyspotCount="'(' + getLanguages.length + ')'"  :filter="false"/>
            <div  class="md-layout">
              <div class="md-layout-item md-size-100 text-center">
                <div v-for="lang in pageOfItems">
                  <LanguageCell :language="lang"/>
                </div>
              </div>
            </div>
            <div class="text-right">
              <jw-pagination :items="getLanguages" @changePage="onChangePage" :pageSize="25"
                             :labels="customLabels"></jw-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LanguageCell from '../../../../shared/components/cells/LanguageCell'
import SearchBar from '../../../../shared/components/SearchBar'
import BreadCrumbs from "../../../../shared/components/Breadcrumb";
import sharedConfig from "../../../../shared/shared-config";

export default {
  bodyClass: "Org-users",
  components: {
    LanguageCell,
    BreadCrumbs,
    SearchBar
  },
  created() {
    document.title = 'Languages - Storyspot';
  },
  data() {
    return {
      searchText: null,
      pageOfItems: [],
      customLabels: {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
      },
      breadcrumbs: [
        {
          to: '/',
          name: "Dashboard"
        },
      ]
    }
  },
  mounted() {
    if (!this.$store.getters.hasLoadedOrganizations) {
      this.$store.dispatch(sharedConfig.store.actions.ORGANIZATION.GET_ALL_REQUEST);
    }
  },
  methods: {
    didChangeSearch(value) {
      this.searchText = value;
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    }
  },
  computed: {
    getLanguages() {
      if (this.searchText) {
        return this.$store.getters.getLanguages.filter((a) => {
          return a.q.includes(this.searchText.toLowerCase());
        })
      }
      return this.$store.getters.getLanguages
    },
    hasLoaded() {
      return this.$store.getters.getHasStaticData;
    }
  }
}
</script>
<style>
.header {
  margin-bottom: 44px;
}
.main {
  min-height: 80vh;
  z-index: 0;
}
.section {
  padding-top: 20px !important;
}
</style>