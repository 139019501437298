<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main main-raised">
        <BreadCrumbs :crumbs="breadcrumbs"/>
        <div class="section">
          <div v-if="!hasLoaded" class="loader md-layout md-gutter md-alignment-center-center">
            <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
          </div>
          <div v-if="hasLoaded" class="container">
            <div  class="md-layout">
              <div class="md-layout-item md-size-50 md-small-size-100 md-small-size-100 md-medium-size-50">
                <md-field required>
                  <md-icon>title</md-icon>
                  <label>Name</label>
                  <md-input v-model="explore.name" type="text"></md-input>
                </md-field>
                <md-field>
                  <label>Categories</label>
                  <md-select v-model="explore.categoryFilter" name="movies" id="movies" multiple>
                    <md-option  v-for="cat in categories" :value="cat.id" :key="cat.id"> {{cat.name }}</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SearchBar from '../../../../shared/components/SearchBar'
import BreadCrumbs from "../../../../shared/components/Breadcrumb";

export default {
  bodyClass: "Org-users",
  components: {
    BreadCrumbs,
    SearchBar
  },
  created() {
    document.title = 'Recommendations explore - Storyspot';
  },
  data() {
    return {
      explore: null,
      originalExplore: null,
      customLabels: {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
      },
      breadcrumbs: [
        {
          to: '/',
          name: "Dashboard"
        },
        {
          to: '/recommendations/explore',
          name: "Explore"
        },
      ]
    }
  },
  methods: {
  },
  computed: {
    categories() {
      return this.$store.getters.getCategories;
    },
    languages() {
      return this.$store.getters.getLanguages;
    },
    getExplore() {
      console.log(this.route.params.id)
      if (this.route.params.id) {
         this.explore = this.$store.getters.getRecommendedExplore.filter((a) => {
          return a.id === this.route.params.id;
        })[0];
         //COPY
         this.originalExplore = JSON.parse(JSON.stringify(this.explore));
      }
    },
    hasLoaded() {
      return this.$store.getters.hasLoadedRecommendations;
    }
  }
}
</script>
<style>
</style>