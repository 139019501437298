import sharedConfig from "../../../shared/shared-config";
import baseAPI from "../../../shared/services/base-api-service"
import {Organization} from "../../../shared/models/Organization.model";

const state = {
    hasLoadedOrganizations: false,
    isLoadingOrganizations: false,
    organizations: [],
}

const getters = {
    getOrganizations(state) {
        return state.organizations;
    },
    hasLoadedOrganizations(state) {
        return state.hasLoadedOrganizations;
    },
    isLoadingOrganizations(state) {
        return state.isLoadingOrganizations;
    }
}

const actions = {
    [sharedConfig.store.actions.ORGANIZATION.GET_ONE_REQUEST]: ({commit, dispatch}, id) => {
        return new Promise(async (resolve, reject) => {
            baseAPI
                .getData(`${sharedConfig.api.organizationEndpoint}/profile/${id}`)
                .then(resp => {
                    let org = new Organization(resp.data);
                    commit(sharedConfig.store.actions.ORGANIZATION.ADD_USERS_TO_ORG_SUCCESS, resp.data);
                    resolve(org);
                })
                .catch(e => {
                    reject(e);
                });

        });
    },
    [sharedConfig.store.actions.ORGANIZATION.GET_ALL_REQUEST]: ({commit, dispatch}) => {
        commit(sharedConfig.store.actions.ORGANIZATION.GET_ALL_REQUEST);
        return new Promise(async (resolve, reject) => {
            baseAPI
                .getData(`${sharedConfig.api.organizationEndpoint}`)
                .then(resp => {
                    commit(sharedConfig.store.actions.ORGANIZATION.GET_ALL_SUCCESS, resp.data);
                    resolve();
                })
                .catch(e => {
                    commit(sharedConfig.store.actions.ORGANIZATION.GET_ALL_ERROR, e);
                    reject(e);
                });

        });
    },
    [sharedConfig.store.actions.ORGANIZATION.ADD_USERS_TO_ORG_REQUEST]: ({commit, dispatch}, data) => {
        return new Promise(async (resolve, reject) => {
            let req = {
                userIds: data.userIds,
                orgId: data.orgId,

            }
            baseAPI
                .postData(`${sharedConfig.api.organizationEndpoint}user`, req)
                .then(resp => {
                    commit(sharedConfig.store.actions.ORGANIZATION.ADD_USERS_TO_ORG_SUCCESS, resp.data);
                    resolve();
                })
                .catch(e => {
                    commit(sharedConfig.store.actions.ORGANIZATION.ADD_USERS_TO_ORG_ERROR, e);
                    reject(e);
                });

        });
    },
}

const mutations = {
    [sharedConfig.store.actions.ORGANIZATION.GET_ALL_REQUEST]: (state, error) => {
        // TODO. manage error response
        state.isLoadingOrganizations = true;
    },
    [sharedConfig.store.actions.ORGANIZATION.GET_ALL_SUCCESS]: (state, orgData) => {
        state.organizations = orgData
            .map((jsonData) => new Organization(jsonData))
            .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
        state.hasLoadedOrganizations = true;
        state.isLoadingOrganizations = false;
    },
    [sharedConfig.store.actions.ORGANIZATION.GET_ALL_ERROR]: (state, error) => {
        // TODO. manage error response
        state.isLoadingOrganizations = false;
    },
    [sharedConfig.store.actions.ORGANIZATION.ADD_USERS_TO_ORG_SUCCESS]: (state, data) => {
        // Updating organization list
        let org = new Organization(data);
        let index = state.organizations.findIndex((a) => a.id = org.id);
        if (index > -1) {
            state.organizations[index] = org;
        } else {
            state.organizations.push(org);
        }
    },
    [sharedConfig.store.actions.ORGANIZATION.GET_ALL_ERROR]: (state, e) => {
        console.log(e);
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};