<template>
  <md-card>
    <h3 class="text-center">{{ label }}</h3>
    <PieChartGenerator
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
    />
  </md-card>
</template>

<script>
import {Pie as PieChartGenerator} from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  ArcElement
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    ArcElement
)

export default {
  name: 'LineChart',
  components: {
    PieChartGenerator
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            loop: false
          }
        },
        plugins: {
          legend: {
            labels: {
              family: 'Raleyway'
            }
          },
        }
      },
    }
  },
  props: {
    label: {
      type: String,
      default: 'By device language'
    },
    chartData: {
      type: Object,
      required: true,
    },
    /* chartOptions: {
       type: Object,
       required: true,
     }, */
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
      }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
}
</script>
