import sharedConfig from "../../../shared/shared-config";
import baseAPI from "../../../shared/services/base-api-service"
import {Report} from "../../../shared/models/Report.model";

const state = {
    hasLoadedReports: false,
    isLoadingReports: false,
    reports: [],
}

const getters = {
    getReports(state) {
        return state.reports;
    },
    hasLoadedReports(state) {
        return state.hasLoadedReports;
    },
    isLoadingReports(state) {
        return state.isLoadingReports;
    }
}

const actions = {
    [sharedConfig.store.actions.ADMIN.GET_REPORTS_REQUEST]: ({commit, dispatch}) => {
        commit(sharedConfig.store.actions.ADMIN.GET_REPORTS_REQUEST);
        return new Promise(async (resolve, reject) => {
            baseAPI
                .getData(`${sharedConfig.api.reports}`)
                .then(resp => {
                    commit(sharedConfig.store.actions.ADMIN.GET_REPORTS_SUCCESS, resp.data);
                    resolve();
                })
                .catch(e => {
                    commit(sharedConfig.store.actions.ADMIN.GET_REPORTS_ERROR, e);
                    reject(e);
                });

        });
    },
}

const mutations = {
    [sharedConfig.store.actions.ADMIN.GET_REPORTS_REQUEST]: (state, error) => {
        // TODO. manage error response
        state.isLoadingreports = true;
    },
    [sharedConfig.store.actions.ADMIN.GET_REPORTS_SUCCESS]: (state, repData) => {
        state.reports = repData
            .map((jsonData) => new Report(jsonData))
            .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
        state.hasLoadedReports = true;
        state.isLoadingReports = false;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};