import sharedConfig from "../../../shared/shared-config";
import baseAPI from "../../../shared/services/base-api-service"
import {Report} from "../../../shared/models/Report.model";
import {Storyspotter} from "../../../shared/models/Storyspotter.model";

const state = {
    isLoading: false,
    hasLoaded: false,
    storyspotters: [],
}

const getters = {
    getStoryspotters(state) {
        return state.storyspotters;
    },
    hasLoadedAdminStats(state) {
        return state.hasLoaded;
    },
    isLoadingAdminStats(state) {
        return state.isLoading;
    }
}

const actions = {
    [sharedConfig.store.actions.ADMIN.GET_STORYSPOTTERS_REQUESTS]: ({commit, dispatch}) => {
        commit(sharedConfig.store.actions.ADMIN.GET_STORYSPOTTERS_REQUESTS);
        return new Promise(async (resolve, reject) => {
            baseAPI
                .getData(`${sharedConfig.api.storyspotters}`)
                .then(resp => {
                    commit(sharedConfig.store.actions.ADMIN.GET_STORYSPOTTERS_SUCCESS, resp.data);
                    resolve();
                })
                .catch(e => {
                    commit(sharedConfig.store.actions.ADMIN.GET_STORYSPOTTERS_ERROR, e);
                    reject(e);
                });

        });
    },
}

const mutations = {
    [sharedConfig.store.actions.ADMIN.GET_STORYSPOTTERS_REQUESTS]: (state, error) => {
        // TODO. manage error response
        state.isLoading = true;
    },
    [sharedConfig.store.actions.ADMIN.GET_STORYSPOTTERS_SUCCESS]: (state, data) => {
        state.storyspotters = data
            .map((jsonData) => new Storyspotter(jsonData));
        state.hasLoaded = true;
        state.isLoading = false;
    },
    [sharedConfig.store.actions.ADMIN.GET_STORYSPOTTERS_ERROR]: (state, error) => {
        // TODO. manage error response
        state.isLoading = false;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};