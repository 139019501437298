<template>
  <div class="md-layout md-gutter md-alignment-center-center">
    <div class="md-layout-item">
      <div style="text-align: center;">
        <md-card class="login-card">
          <div style="text-align: center;">
            <img
                class="logo-img"
                src="../assets/logo_480.png"
                alt="Storyspot logo"
            />
          </div>
          <h1 class="text-center">Login</h1>
          <md-card-content class="content">
            <md-field required :class="{ 'md-invalid': formValidators.email }">
              <md-icon>email</md-icon>
              <label>Email</label>
              <md-input v-model="form.email" type="text"></md-input>
              <span class="md-error">{{ formValidators.email }}</span>
            </md-field>
            <md-field required :class="{ 'md-invalid': formValidators.password }">
              <md-icon>password</md-icon>
              <label>Password</label>
              <md-input v-model="form.password" type="password"></md-input>
              <span class="md-error">{{ formValidators.password }}</span>
            </md-field>
          </md-card-content>

          <md-card-actions class="buttons md-layout md-gutter md-alignment-center-center">
            <md-button class="md-primary btn-regular" v-bind:class="{ disabled: !validateLogin(form.email) }" @click="login">
             Login
             </md-button>
          </md-card-actions>
          <div class="secondary-actions content">
          <p><a @click="openForgotPassword">Forgot password?</a></p>
          </div>
        </md-card>
      </div>
    </div>
    <modal v-if="showForgotPassword" @close="closeForgotPassword">
      <template slot="header">
        <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="closeForgotPassword"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <h3>Forgot password?</h3>
        <br>
        <p>Fill in your email and we will send you a password reset request</p>
        <md-field class="md-form-group" :class="loginFormErrorEmail" slot="inputs">
          <md-icon>email</md-icon>
          <label>Email...</label>
          <md-input v-model="form.email" type="email"></md-input>
          <span class="md-error">{{ form.emailError }}</span>
        </md-field>
        <md-button class="md-primary" @click="resetPassword"  v-bind:class="{ disabled: !validateEmail(form.email) }">Send reset</md-button>
      </template>
    </modal>
  </div>
</template>
<script>
import store from "../store";
import sharedConfig from "../../../shared/shared-config";
import Vue from "vue";
import Modal from "../../../shared/components/Modal";
import {validateEmail} from "../../../shared/utils/general-utils";

export default {
  bodyClass: "login",
  components: {
    Modal
  },
  created() {
    document.title = "Login - Organization";
  },
  data() {
    return {
      showForgotPassword: false,
      form: {
        email: "",
        emailError: false,
        password: "",
        passwordError: false,
        error: "",
        signInError: ""
      },
      formValidators: {}
    };
  },
  methods: {
    checkSignIn() {
      let error = false;
      if (!this.form.email) {
        this.form.error.concat("Email required");
        this.form.emailError = "Email required";
        error = true;
      } else if (!this.validateEmail(this.form.email)) {
        this.form.error.concat("Not a valid email\n");
        this.form.emailError = "Email invalid";
        error = true;
      }
      if (!this.form.password) {
        this.form.error.concat("Password required");
        this.form.passwordError = "Password required";
        error = true;
      }

      return !error;
    },
    login: function (event) {
      event.preventDefault();
      if (this.checkSignIn()) {
        this.$store.dispatch(sharedConfig.store.actions.AUTH.LOGIN_REQUEST, this.form);
      }
    },
    validateEmail(email) {
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
    validateLogin() {
      return String(this.form.email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) && this.form.password.length > 4;
    },
    closeForgotPassword() {
      this.showForgotPassword = false;
    },
    openForgotPassword() {
      this.showForgotPassword = true;
    },
    resetPassword: function () {
      if (this.form.email && this.form.email.length > 5) {
        this.$store
            .dispatch(sharedConfig.store.verifications.FORGOT_PASSWORD_REQUEST, this.form.email)
            .then(() => {
              this.closeForgotPassword();
              const instance = Vue.$toast.open({
                message: "Reset email sent",
                type: "success",
                position: "top-right"
              });
            }).catch((e) => {
          const instance = Vue.$toast.open({
            message: "Could not send password reset",
            type: "error",
            position: "top-right"
          });
        });
      } else {
        const instance = Vue.$toast.open({
          message: "Invalid email",
          type: "info",
          position: "top-right"
        });
      }
    }
  },
  computed: {
    signInError() {
      return store.getters.signInError;
    },
    loginActionError() {
      return store.getters.getLoginDetails.error;
    },
    loginFormError() {
      return {
        'md-invalid': this.form.error.length > 0
      }
    },
    loginFormErrorEmail() {
      return {
        'md-invalid': this.form.emailError.length > 0
      }
    },
    loginFormErrorPassword() {
      return {
        'md-invalid': this.signInError || this.form.passwordError.length > 0
      }
    }
  }
}
</script>

<style scoped>
.login-card {
  width: 380px;
  margin: auto;
}

.logo-img {
  width: 200px;
  height: auto;
  margin: 25px auto auto auto;
}

.content {
  margin: 0 auto 0 auto;
}

.buttons {
  margin: 0 0 25px 0 !important;
}
a {
  cursor: pointer;
}
</style>