<template>
  <div>
    <md-field class="md-form-group">
      <md-icon>search</md-icon>
      <md-input
          v-model="searchText"
          placeholder="Search..."
      ></md-input>
    </md-field>
    <div>
      <div class="user-container" v-for="usr in getUsers" v-bind:key="usr.id">
        <div>
          <md-checkbox v-model="selectedUsers" :value="usr"><p class="large-paragraph">{{usr.email}}</p></md-checkbox>
        </div>

      </div>
    </div>
    <div v-if="selectedUsers.length > 0">
      <p class="small-paragraph text-left">Selected users</p>
      <hr>
      <div>
        <div class="user-container" v-for="usr in selectedUsers" v-bind:key="usr.id">
          <div>
            <md-checkbox v-model="selectedUsers" :value="usr"><p class="large-paragraph">{{usr.email}}</p></md-checkbox>
          </div>
        </div>
      </div>
      <div class="add-user-container">
        <md-button class="md-primary btn-regular" @click="addUsers">
          <md-icon>add</md-icon>
          Add
        </md-button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  prop: {
    add: { type: Function },
  },
  data() {
    return {
      searchText: null,
      selectedUsers: [],
    };
  },
  methods: {
    addUsers() {
      //this.add(this.selectedUsers)
      this.$emit('add-users', this.selectedUsers);
    },
  },
  computed: {
    getUsers() {
      if (this.searchText) {
        return this.$store.getters.getUsers.filter(
            (a) =>
                a.q && a.q.indexOf(this.searchText.toLowerCase()) > -1
        ).slice(0,8);
      }
      return [];
    }
  }
};
</script>
<style scoped>
.small-paragraph {
  margin-top: 26px;
}
.user-container {
  margin-top:auto;
  margin-bottom:auto;
  text-align:left;
}

.add-user-container {
  margin-left: auto;
  margin-right: 0;
}
</style>