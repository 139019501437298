<template>
  <div>
    <div class="main-content">
      <div class="wrapper">

        <div class="main main-raised">

          <div class="section">
            <div v-if="loading" class="loader md-layout md-gutter md-alignment-center-center">
              <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
            </div>
            <div v-if="!loading" class="container">
              <div class="header">
                <h1>
                  Organization
                </h1>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-100 text-center">
                  <ProfilePictureSelector :image="getProfilePic" @on-delete="onImageDeleted"
                                          @on-selected="onImageSelected" :allowEdit="false"/>
                </div>
              </div>
              <div class="md-layout details">
                <div class="md-layout-item md-size-50 md-small-size-100 md-small-size-100 md-medium-size-50">
                  <md-field  v-if="organization && organization.profile && organization.profile.fullName">
                    <md-icon>person</md-icon>
                    <label>Name (display name)</label>
                    <md-input v-model="organization.profile.fullName" type="text" disabled></md-input>
                  </md-field>
                  <md-field>
                    <md-icon>badge</md-icon>
                    <label>Username</label>
                    <md-input v-model="organization.profile.username" type="text" disabled></md-input>
                  </md-field>
                  <md-field>
                    <md-icon class="icon-textarea">description</md-icon>
                    <label class="label-textarea">Description</label>
                    <md-textarea v-model="organization.profile.description" type="text" disabled></md-textarea>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50 md-small-size-100 md-small-size-100 md-medium-size-50">
                  <md-field>
                    <md-icon>email</md-icon>
                    <label>Email</label>
                    <md-input v-model="organization.profile.email" type="email" disabled></md-input>
                  </md-field>
                  <md-switch class="md-primary" v-model="organization.profile.showEmail" disabled>
                    {{ organization.profile.showEmail ? 'My email is ' : 'Keeping my email' }}
                    <strong>{{ organization.profile.showEmail ? 'Public' : 'Private' }}</strong>
                  </md-switch>
                </div>
              </div>
              <div class="second">
              <p class="large-paragraph">Administration managed</p>
              <hr>
              <div class="top-pad md-layout-item md-size-50 md-small-size-100">
                <Autocomplete  :options="subscriptions" label="Subscription" icon="loyalty" :selected="changeSubscription" :initValue="currentSubscription"/>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isLoading" class="main main-raised second">
      <div class="section">
        <div  class="container">
          <SearchBar class="top-padding" @send-searchparameter="didChangeSearchStoryspot" :title="getName() + ' storyspots'"
                     :storyspotCount="'(' + getStoryspots.length + ')'"/>
          <div v-for="story in getStoryspots" v-bind:key="story.id">
            <StoryspotSmall :storyspot="story" :toSpotBasePath="'/storyspots/'"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="main main-raised second">
      <div class="section">
        <div class="container">
          <div class="add-user-container">
              <md-button class="md-primary btn-regular" @click="addUserModalShow">
                <md-icon>add</md-icon>
                Add user
              </md-button>
          </div>
          <SearchBar class="top-padding" @send-searchparameter="didChangeSearchOrgUsers" :title="'Organization users'"
                     :storyspotCount="'(' + getOrgUsers.length + ')'"/>
          <div v-for="usr in getOrgUsers" v-bind:key="usr.id">
            <UserCell :user="usr"/>
          </div>
        </div>
      </div>
    </div>
    <modal v-if="showAddUserModal" @close="addUserModalClose">
      <template slot="header">
        <h1 class="modal-title">Add user to Organization</h1>
        <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="addUserModalClose"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <SearchUserModal @add-users="addUsers"/>
      </template>

    </modal>
  </div>
</template>

<script>
import ProfilePictureSelector from "../../../shared/components/ProfilePictureSelector"
import sharedConfig from "../../../shared/shared-config"
import SearchBar from "../../../shared/components/SearchBar"
import StoryspotSmall from "../../../shared/components/cells/StoryspotCell"
import UserCell from "../../../shared/components/cells/UserCell"
import Autocomplete from "../../../shared/components/Autocomplete";
import Modal from "../../../shared/components/Modal";
import SearchUserModal from "../../../shared/components/SearchUserModal";

export default {
  bodyClass: "Users",
  components: {
    ProfilePictureSelector,
    SearchBar,
    StoryspotSmall,
    UserCell,
    Autocomplete,
    Modal,
    SearchUserModal
  },
  created() {
    document.title = "Organization - Admin panel";
  },
  data() {
    return {
      isLoading: true,
      isLoadingStoryspots: true,
      organization: {},
      searchText: null,
      searchTextStoryspot: null,
      searchTextOrgUsr: null,
      showAddUserModal: false
    };
  },
  mounted() {
    this.isLoading = true;
    if (!this.$store.getters.hasLoadedOrganizations) {
      this.$store.dispatch(sharedConfig.store.actions.ORGANIZATION.GET_ONE_REQUEST, this.$route.params.id).then((org) => {
        this.organization = org;
        this.$nextTick(() => {
          this.isLoading = false;
        })
        document.title = this.getName() + " - Admin panel";
      })
    } else {
      let org = this.$store.getters.getOrganizations.filter((a) => a.id === this.$route.params.id);
      if (org) {
        this.organization = org[0];
        this.$nextTick(() => {
          this.isLoading = false;
        })
      }
    }
    if (!this.$store.getters.hasLoadedStoryspots) {
      this.$store.dispatch(sharedConfig.store.actions.ADMIN.GET_ALL_STORYSPOTS_REQUEST).then(() => {
        this.isLoadingStoryspots = false;
      });
    } else {
      this.isLoadingStoryspots = false;
    }

  },
  methods: {
    addUsers(users) {
      let req = {
        userIds: users.map((a) => a.id),
        orgId: this.organization.id
      }
      this.$store.dispatch(sharedConfig.store.actions.ORGANIZATION.ADD_USERS_TO_ORG_REQUEST, req).then(() => {
        this.addUserModalClose();
        this.$store.dispatch(sharedConfig.store.actions.ORGANIZATION.GET_ONE_REQUEST, this.$route.params.id).then((org) => {
          this.organization = org;
        });
      });
    },
    addUserModalShow() {
      this.showAddUserModal = true;
    },
    addUserModalClose() {
      this.showAddUserModal = false;
    },
    changeSubscription(value) {
      this.organization.subscription = value;
    },
    getName() {
      if (this.organization && this.organization.profile && this.organization.profile.fullName) {
        return this.organization.profile.fullName;
      } else {
        return this.organization.profile.username;
      }
    },
    didChangeSearch(value) {
      this.searchText = value;
    },
    didChangeSearchStoryspot(value) {
      this.searchTextStoryspot = value;
    },
    didChangeSearchOrgUsers(value) {
      this.searchTextOrgUsr = value;
    },
    onImageSelected(img) {
      this.setValue(img, 'profileImage');
    },
    onImageDeleted() {
      this.setValue(null, 'profileImage');
    }
  },
  computed: {
    getProfilePic() {
      if (this.organization && this.organization.profile && this.organization.profile.picture) {
        return this.organization.profile.picture;
      }
    },
    currentSubscription() {
      return {name: this.organization.subscription, value: this.organization.subscription}
    },
    subscriptions() {
        return this.$store.getters.getStaticHasLoaded ? this.$store.getters.getSubscriptions : [];
    },
    getOrgUsers() {
      if (!this.organization && this.organization.users) {
          return [];
        }
        if (this.searchTextOrgUsr) {
          return this.organization.users.filter(
              (a) => a.q && a.q.indexOf(this.searchTextOrgUsr.toLowerCase()) > -1
          );
        }
        return this.organization.users;
    },
    getStoryspots() {
      if (!this.organization && this.organization.profile && !this.$store.getters.hasLoadedStoryspots) {
        return [];
      }
      if (this.searchTextStoryspot) {
        return this.$store.getters.getStoryspots.filter(
            (a) =>
                (a.author.id === this.organization.profile.id) && a.q && a.q.indexOf(this.searchTextStoryspot.toLowerCase()) > -1
        );
      }
      return this.$store.getters.getStoryspots.filter((a) => (a.author.id === this.organization.profile.id));
    },
    loading() {
      return !this.organization.profile;
    }
  }
};
</script>

<style lang="scss" scoped>
.second {
  margin-top: 120px;
}

.add-user-container {
  margin-left: auto;
  margin-right: 0;
}

.top-pad {
  margin-top: 45px;
}

.header {
  margin-bottom: 44px;
}


.image-button-container .md-button {
  width: 100%;
  max-width: 200px;
}

.details {
  margin-top: 45px;
}

textarea {
  display: block;
  padding-left: 0 !important;
  resize: none !important;
}


.section {
  // Used cause we want the create storyspot and search header to act as section "headers"
  padding-top: 20px !important;
}

.main {
  min-height: 80vh;
  z-index: 0;
}

.button-container {
  margin: auto;
  margin-top: 44px;
}

.md-textarea {
  padding-left: 22px !important;
}

.md-field.md-has-textarea:not(.md-autogrow) > .md-icon {
  right: unset;
}

.label-textarea {
  margin-left: 33px;
}

.md-field + .md-has-textarea:not(.md-autogrow) {
  margin-top: 0 !important;
}


</style>
