<template>
  <div class="md-layout md-gutter md-alignment-center-center">
    <div class="md-layout-item">
      <div style="text-align: center;">
        <md-card class="login-card">
          <div style="text-align: center;">
            <img
                class="logo-img"
                src="../assets/logo_480.png"
                alt="Storyspot logo"
            />
          </div>
          <div v-if="!hasLoaded">
          <h1 class="text-center">Reset password</h1>
          <md-card-content class="content">
            <md-field required :class="{ 'md-invalid': validatePassword1 }">
              <label>Enter new password</label>
              <md-input v-model="resetForm.password1" type="text"></md-input>
              <span class="md-error">{{ validatePassword2 }}</span>
            </md-field>
            <md-field required :class="{ 'md-invalid': validatePassword2 }">
              <md-icon>password</md-icon>
              <label>Password</label>
              <md-input v-model="resetForm.password2" type="password"></md-input>
              <span class="md-error">{{ validatePassword2 }}</span>
            </md-field>
          </md-card-content>
          <md-card-actions class="buttons md-layout md-gutter md-alignment-center-center">
            <md-button class="md-primary btn-regular" v-bind:class="{ disabled: validatePassword1 && validatePassword2 }" @click="resetPassword">
              Login
            </md-button>
          </md-card-actions>
          </div>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import authService from "../../../shared/services/user-service";

export default Vue.extend({
  components: {
  },
  data: function() {
    return {
      hasLoaded: false,
      isValidToken: false,
      didSuccessfullyChange: false,
      error: "",
      resetForm: {
        token: this.$route.params.token,
        formError: "",
        password1: "",
        password2: ""
      }
    };
  },
  computed: {
    validatePassword1: function() {
      if (this.resetForm.password1 && this.resetForm.password1.length > 8) {
        return null;
      } else if (this.resetForm.password1 && this.resetForm.password1.length > 1) {
        return "Password needs to be at least 6 characters";
      }
    },
    validatePassword2: function() {
      if (this.resetForm.password2 && this.resetForm.password2.length > 1) {
        return null;
      } else {
        return "Password does not match";
      }
    }
  },
  methods: {
    resetPassword: function() {
      this.resetForm.formError = "";
      if (this.resetForm.password1.length < 8) {
        this.resetForm.formError = "Password needs to be at least 8 chars";
      } else if (this.resetForm.password1 === this.resetForm.password2) {
      authService
            .resetPassword(this.$route.params.id, this.resetForm.password1)
            .then(resp => {
              this.didSuccessfullyChange = true;
            })
            .catch(error => {
              this.error = error;
            });
      } else {
        this.resetForm.formError = "Passwords do not match";
      }
    },
  },
  created() {
    document.title = "Reset password";
  },
  mounted() {
    console.log('did mount');
    authService.checkRestToken(this.$route.params.id)
        .then(resp => {
          console.log('valid token');
          this.hasLoaded = true;
          this.isValidToken = true;
        })
        .catch(e => {
          console.log('Invalid token');
          this.hasLoaded = true;
          this.isValidToken = false;
          this.error = e;
        });
  }
});
</script>
<style>
</style>
