import storage from "../../../shared/utils/localstorage-handler";
import session from "../../../shared/utils/session-handler";
import sharedConfig from "../../../shared/shared-config";
import authService from "../../../shared/services/user-service"
import router from "../router";
import {User} from "../../../shared/models/CurrentUser.model";
import Vue from "vue";

const state = {
    isLoadingLogin: false,
    signInError: '',
    isAuthenticated: storage.get(sharedConfig.storage.token) != null,
    user:
        storage.get(sharedConfig.storage.user) != null
            ? storage.get(sharedConfig.storage.user)
            : null,
    registerError: '',
}

const getters = {
    isAuthenticated(state) {
        return storage.get(sharedConfig.storage.token);
    },
    isAdmin(state) {
        return state.user && state.user.role && state.user.role.toLowerCase() === "administrator";
    },
    isPartOfOrganization(state) {
        return state.user.organization != null;
    },
    getLoginDetails(state) {
        return {
            isLoading: state.isLoadingLogin,
            error: state.signInError,
        }
    },
    getCurrentUser(state) {
        return state.user;
    },
    getCurrentProfile(state) {
        return state.organization.profile;
    },
    signInError(state) {
        return state.signInError;
    },
}

const actions = {
    [sharedConfig.store.actions.AUTH.LOGIN_REQUEST]: (
        {commit, dispatch},
        credentials
    ) => {
        commit(sharedConfig.store.actions.AUTH.LOGIN_REQUEST);
        authService
            .loginEmail(credentials.email, credentials.password)
            .then(resp => {
                commit(sharedConfig.store.actions.AUTH.LOGIN_SUCCESS, resp);
                dispatch(sharedConfig.store.actions.STATIC.GET_STATIC_DATA_REQUEST);
            })
            .catch(error => {
                commit(sharedConfig.store.actions.AUTH.LOGIN_ERROR, error.response);
            });
    },
    [sharedConfig.store.actions.AUTH.GET_PROFILE]: (
        {commit, dispatch}
    ) => {
        commit(sharedConfig.store.actions.AUTH.LOGIN_REQUEST);
        authService
            .getUser()
            .then(resp => {
                commit(sharedConfig.store.actions.AUTH.GET_PROFILE_SUCCESS, resp);
            })
            .catch(error => {
                if (error.statusCode) {
                    commit(sharedConfig.store.actions.AUTH.LOGOUT_SUCCESS);
                }
                commit(sharedConfig.store.actions.AUTH.LOGIN_ERROR, error.response);
            });
    },
    [sharedConfig.store.actions.AUTH.GOOGLE_LOGIN_REQUEST]: (
        {commit, dispatch},
        gToken
    ) => {
        commit(sharedConfig.store.actions.AUTH.LOGIN_REQUEST);
        authService
            .loginGoogle(gToken)
            .then(resp => {
                commit(sharedConfig.store.actions.AUTH.LOGIN_SUCCESS, resp);
                dispatch(sharedConfig.store.actions.STATIC.GET_STATIC_DATA_REQUEST);
            })
            .catch(error => {
                commit(sharedConfig.store.actions.AUTH.LOGIN_ERROR, error.response);
            });
    },
    [sharedConfig.store.actions.AUTH.APPLE_LOGIN_REQUEST]: (
        {commit, dispatch},
        aToken
    ) => {
        commit(sharedConfig.store.actions.AUTH.LOGIN_REQUEST);
        authService
            .loginApple(aToken)
            .then(resp => {
                commit(sharedConfig.store.actions.AUTH.LOGIN_SUCCESS, resp);
                dispatch(sharedConfig.store.actions.STATIC.GET_STATIC_DATA_REQUEST);
            })
            .catch(error => {
                commit(sharedConfig.store.actions.AUTH.LOGIN_ERROR, error.response);
            });
    },
    [sharedConfig.store.actions.AUTH.LOGOUT_REQUEST]: ({commit, dispatch}) => {
        commit(sharedConfig.store.actions.AUTH.LOGOUT_SUCCESS);
        dispatch(sharedConfig.store.analytics.CLEAR_DATA);
    },
}

const mutations = {
    [sharedConfig.store.actions.AUTH.LOGIN_REQUEST]: (state, resp) => {
        // setting loading
        state.isLoadingLogin = true;
        // resetting if any error message
        state.signInError = "";
    },
    [sharedConfig.store.actions.AUTH.LOGIN_SUCCESS]: (state, resp) => {

        let usr = new User(resp.data.user);

        if (usr.role.toLowerCase() === 'administrator') {
            // Is set in storage from interceptor
            state.token = storage.get(sharedConfig.storage.token);
            storage.set(sharedConfig.storage.user, resp.data.user);
            session.set('session', {id: usr.id, date: new Date()})

            state.user = usr;
            state.isAuthenticated = true;
            state.isLoadingLogin = false;

            router.push("/dashboard");

            const instance = Vue.$toast.open({
                message: "Welcome " + (state.user && state.user.fullName ? state.user.fullName : state.user.username),
                type: "success",
                position: "bottom"
            });
        }
        // this.$analytics.setUserId(state.user.id);
    },
    [sharedConfig.store.actions.AUTH.GET_PROFILE_SUCCESS]: (state, resp) => {

        let usr = new User(resp.data.user);
        storage.set(sharedConfig.storage.user, resp.data.user);
        state.user = usr;
        state.isAuthenticated = true;
        state.isLoadingLogin = false;
    },
    [sharedConfig.store.actions.AUTH.LOGIN_ERROR]: (state, error) => {
        state.isAuthenticated = false;
        state.isLoadingLogin = false;
        state.signInError =
            error?.response?.message != null
                ? error.response.message
                : "There was an error signing in.";

    },
    [sharedConfig.store.actions.AUTH.LOGOUT_SUCCESS]: state => {
        storage.delete(sharedConfig.storage.token);
        storage.delete(sharedConfig.storage.refreshToken);
        storage.delete(sharedConfig.storage.user);
        storage.delete(sharedConfig.storage.organizaiton);
        state.isAuthenticated = false;
        state.user = null;
        state.organization = null;
        router.push("/");
        //this.$analytics.signOut();
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};


const validateName = function (name) {
    if (!name) {
        return "Name is required"
    } else if (name.length < 5) {
        return "Name needs to be at least 5 characters long"
    }
    return null;
}