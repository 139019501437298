export const getLastDayOfMonth = function (y, m) {
    return  new Date(y, m +1, 0).getDate();
}

export const getMonthTimeRange = function (date) {
    let dateStart = new Date(date.getFullYear(), date.getMonth(), 1, 0,0,0);
    let dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    return {
        start: dateStart,
        end: dateEnd,
    }
}

export const getToday = function () {
    let date = new Date();
    let dateStart = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0,0,0);
    let dateEnd =  new Date(date.getFullYear(), date.getMonth(), date.getDate()+1, 0,0,0);
    return {
        start: dateStart,
        end: dateEnd,
    }
}

const weeksBetween = function (d1, d2) {
    return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
}

export const getDifferenceInDays = function (date2, date1) {
    let Difference_In_Time = date2.getTime() - date1.getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days;
}

export const relDiff = function (a, b) {
    return  100 * Math.abs( ( a - b ) / ( (a+b)/2 ) );
}

export const percentage = function(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
}


export const comparePreviousVScurrent = function(current, previous) {
    let diff, increase;
    diff = (((current.length / previous.length) - 1) * 100).toFixed(0) + '%'
    if (previous > current) {
        increase = false;
    } else if (current > previous) {
        increase = true;
    }
    return {diff, increase};
}

export const getEventsOverTimeByDay = function (value, fromDate, toDate, key = 'date') {
    let keys = [];
    let values = [];
    let lastDay = getDifferenceInDays(toDate, fromDate);
    for (let i=lastDay; i>0; i--) {
        let startDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate() - i, 0, 0, 0, 999)
        let endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 59, 999)
        let match = value && value.length > 0 ? value.filter((a) => {return a[key] > startDate && a[key] < endDate}).length : 0;
        keys.push(startDate);
        values.push(match);
    }
    return {
        keys,
        values,
    }
}

export const getEventsOverTimeByWeek = function (value, fromDate, toDate) {
    let weeks = weeksBetween(fromDate, toDate);
    console.log(weeks);
   /* let keys = [];
    let values = [];
    let lastDay = getDifferenceInDays(toDate, fromDate);
    for (let i=lastDay; i>0; i--) {
        let startDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate() - i, 0, 0, 0, 999)
        let endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 59, 999)
        let match = value && value.length > 0 ? value.filter((a) => a.date > startDate && a.date < endDate) : {length: 0};
        keys.push(startDate);
        values.push(match.length);
    }
    return {
        keys,
        values,
    } */
}

export const getCitiesAndCountries = function (value, cities, countries) {



    for (let i = 0; i < value.length; i++) {
        if (!value[i].country || value[i].country === '' || value[i].country === '(not set)') {
            value[i].country = 'Unknown';
        }
        if (!value[i].city || value[i].city === '' || value[i].city === '(not set)') {
            value[i].city = 'Unknown';
        }

        if (value[i].country in countries) {
            countries[value[i].country] = countries[value[i].country] + 1;
        } else {
            countries[value[i].country] = 1;
        }
        if (value[i].city in cities) {
            cities[value[i].city] = cities[value[i].city] + 1;
        } else {
            cities[value[i].city] = 1;
        }
    }
}

const lineColors = ['#6c5ce7', '#fdcb6e', '#05c46b'];
export const lineChartv2 = function (label, datasets) {
    return {
        'labels': label,
        'datasets': datasets,
    };
}

export const graphColors = ['#A33E00', '#FF8500', '#ba8360', '#a65f33', '#d9bca8', '#e671b8','#474D84', '#a05000', '#0fb9b1', '#a55eea', '#45aaf2', '#fa8231', '#ffa801', '#3c40c6', '#E69A8DFF']


export const adminGraphColors = ['#299df6', '#0722b8', '#1b78bf', '#13466e', '#052945', '#344c5e','#474D84', '#a05000', '#435b6e', '#a55eea', '#45aaf2', '#fa8231', '#ffa801', '#3c40c6', '#E69A8DFF']