<template>
  <md-card>
    <h3 class="text-center">{{ label }}</h3>
    <Bar
        class="holder"
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
    />
  </md-card>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement
)

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        transitions: {
          show: {
            animations: {
              x: {
                from: 0
              },
              y: {
                from: 0
              }
            }
          },
          hide: {
            animations: {
              x: {
                to: 0
              },
              y: {
                to: 0
              }
            }
          },
        },
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            loop: false
          }
        },
        plugins: {
          legend: {
            display: false,
            labels: {
              family: 'Raleyway'
            }
          },
        }
      },
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    chartData: {
      type: Object,
      required: true,
    },
    /* chartOptions: {
       type: Object,
       required: true,
     }, */
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
      }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
}
</script>
<style>
.holder {
  padding: 50px ;
}
</style>
