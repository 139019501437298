import baseApi from '../../../shared/services/base-api-service'
import sharedConfig from "../../../shared/shared-config";
import Vue from "vue";

const state = {
    hasLoaded: false,
    isLoading: false,
    stats: null,
    hasLoadedStats: false,
}
const getters = {
    dashboardStats(state) {
        return state.stats;
    },
    hasLoadedStats(state) {
        return state.hasLoaded;
    },
    isLoadingStats(state) {
        return state.isLoading;
    }
}

const actions = {
    // Used for logging out etc.
    [sharedConfig.store.analytics.CLEAR_DATA]: (
        { commit, dispatch }
    ) => {
        commit(sharedConfig.store.analytics.CLEAR_DATA_SUCCESS);
    },
    [sharedConfig.store.analytics.GET_STATS]: (
        { commit, dispatch }, startDate
    ) => {
        commit(sharedConfig.store.analytics.GET_STATS);
        let date = startDate.toISOString().split('T')[0]
        return new Promise((resolve, reject) =>  {
            baseApi.getData(sharedConfig.api.admin.stats + '/?startDate=' + date)
                .then((statsData) => {
                    commit(sharedConfig.store.analytics.GET_STATS_SUCCESS, statsData);
                    resolve();
                }).catch((e) => {
                commit(sharedConfig.store.analytics.GET_STATS_ERROR, e);
                console.log(e);
                reject(e);
            });
        })

    },
}

const mutations = {
    [sharedConfig.store.analytics.SET_STATS]: (state, data) => {
        if (data.key === 'users') {
            state.stats.users = data.value;
        } else if (data.key === 'storyspots') {
            state.stats.storyspots = data.value;
        }
    },
    [sharedConfig.store.analytics.CLEAR_DATA_SUCCESS]: (state) => {
        state.stats = null;
        state.hasLoaded = false;
        state.isLoading = false;
    },
    [sharedConfig.store.analytics.GET_STATS]: (state) => {
        state.hasLoaded = false;
    },
    [sharedConfig.store.analytics.GET_STATS_SUCCESS]: (state, resp) => {
        state.stats = resp.data;
        state.isLoading = false;
        state.hasLoaded = true;
    },
    [sharedConfig.store.analytics.GET_STATS_ERROR]: (state, e) => {
        state.isLoading = false;
        const instance = Vue.$toast.open({
            message: e,
            type: "error",
            position: "top-right"
        });
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};