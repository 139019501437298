
import sharedConfig from "../../../shared/shared-config";
import baseAPI from "../../../shared/services/base-api-service"
import {Author} from "../../../shared/models/Author.model";
import Vue from "vue";

const state = {
    hasLoadedUsers: false,
    isLoadingUsers: false,
    users: [],
}

const getters = {
    getUsers(state) {
        return state.users;
    },
    hasLoadedUsers(state) {
        return state.hasLoadedUsers;
    },
    isLoadingUsers(state) {
        return state.isLoadingUsers;
    }
}

const actions = {
    [sharedConfig.store.actions.ADMIN.GET_ALL_USERS_REQUESTS]: ({commit, dispatch}) => {
        commit(sharedConfig.store.actions.ADMIN.GET_ALL_USERS_REQUESTS);
        return new Promise(async (resolve, reject) => {
            baseAPI
                .getData(`${sharedConfig.api.userEndpoint}`)
                .then(resp => {
                    commit(sharedConfig.store.actions.ADMIN.GET_ALL_USERS_SUCCESS, resp.data);
                    resolve();
                })
                .catch(e => {
                    commit(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_ERROR, e);
                    reject(e);
                });

        });
    },
    [sharedConfig.store.actions.ADMIN.GET_SPECIFIC_USER_REQUESTS]: ({commit, dispatch}, id) => {
        return new Promise(async (resolve, reject) => {
            baseAPI
                .getData(`${sharedConfig.api.userEndpoint}${id}`)
                .then(resp => {
                    resolve(new Author(resp.data));
                })
                .catch(e => {
                    reject(e);
                });

        });
    },
    [sharedConfig.store.actions.ADMIN.UPDATE_USER_REQUEST]: ({ commit, dispatch }, update) => {
        const data = {};
        // TODO: make this better, handled by model etc instead. but for now, in a hurry and we only want to change status
        const allowedChanges = [
            "email",
            "fullName",
            "profileImage",
            "description",
            "showEmail",
            "socialLinks",
            "countryCode",
            "removeImage",
            "username"
        ];
        const formData = new FormData();
        if (!update.data) {
            return;
        }
        for (let i = 0; i < Object.keys(update.data).length; i++) {
            if (allowedChanges.includes(Object.keys(update.data)[i])) {
                // Hacky way for special cases.
                formData.append(
                    Object.keys(update.data)[i],
                    update.data[Object.keys(update.data)[i]]
                );
            }
        }
        formData.append(
            'userId',
            update.id
        );
        return new Promise((resolve, reject) => {
            baseAPI
                .putFormData(sharedConfig.api.admin.updateUser, formData)
                .then(resp => {
                    console.log('reeesp', resp);
                    commit(sharedConfig.store.actions.ADMIN.UPDATE_USER_SUCCESS, resp);
                    const instance = Vue.$toast.open({
                        message: "User updated",
                        type: "success",
                        position: "bottom"
                    });
                    resolve();
                })
                .catch(e => {
                    reject(e);
                    const instance = Vue.$toast.open({
                        message: "Update failed: " + e,
                        type: "error",
                        position: "bottom"
                    });
                });
        });
    }
}

const mutations = {
    [sharedConfig.store.actions.ADMIN.GET_ALL_USERS_REQUESTS]: (state, data) => {
        state.isLoadingUsers = true;
    },
    [sharedConfig.store.actions.ADMIN.GET_ALL_USERS_SUCCESS]: (state, data) => {
        state.users = data.map((a) => new Author(a)).sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
        state.hasLoadedUsers = true;
        state.isLoadingUsers = false;
    },
    [sharedConfig.store.actions.ADMIN.UPDATE_USER_SUCCESS]: (state, data) => {
        let index = state.users.findIndex((a) => a.id === data.id);
        state.users[index] = new Author(data);
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};