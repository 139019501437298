<template>
  <div>
    <div class="main-content">
      <div class="wrapper">
        <div class="main main-raised">
          <div class="section">
            <div v-if="isLoading || updating" class="loader md-layout md-gutter md-alignment-center-center">
              <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
            </div>
            <div v-if="!isLoading" class="container">
              <div class="header">
                <h1>
                  User
                </h1>
                <md-button
                    class="md-simple md-just-icon md-round modal-default-button"
                    :class="{'md-primary': editmode}"
                    @click="editToggle"
                >
                  <md-icon>edit</md-icon>
                </md-button>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-100 text-center">
                  <ProfilePictureSelector :image="user.picture" @on-delete="onImageDeleted"
                                          @on-selected="onImageSelected" :allowEdit="false"/>
                </div>
              </div>
              <div class="md-layout details">
                <div class="md-layout-item md-size-50 md-small-size-100 md-small-size-100 md-medium-size-50">
                  <md-field>
                    <md-icon>person</md-icon>
                    <label>Name (display name)</label>
                    <md-input v-model="user.fullName" type="text" :disabled="!editmode"></md-input>
                  </md-field>
                  <md-field>
                    <md-icon>badge</md-icon>
                    <label>Username</label>
                    <md-input v-model="user.username" type="text" :disabled="!editmode"></md-input>
                  </md-field>
                  <md-field>
                    <md-icon>loyalty</md-icon>
                    <label>Role</label>
                    <md-input v-model="user.role" type="text" disabled></md-input>
                  </md-field>
                  <md-field>
                    <md-icon class="icon-textarea">description</md-icon>
                    <label class="label-textarea">Description</label>
                    <md-textarea v-model="user.description" type="text" :disabled="!editmode">
                    </md-textarea>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50 md-small-size-100 md-small-size-100 md-medium-size-50">
                  <md-field>
                    <md-icon>email</md-icon>
                    <label>Email</label>
                    <md-input v-model="user.email" type="email" :disabled="!editmode"></md-input>
                  </md-field>
                  <md-switch class="md-primary" v-model="user.showEmail" :disabled="!editmode">
                    Email visability is
                    <strong>{{ user.showEmail ? 'Public' : 'Private' }}</strong>
                  </md-switch>
                </div>
              </div>
            </div>
            <p class="error" v-if="error != null">{{ error }}</p>
            <div class="md-layout" v-if="editmode">
              <div class="md-layout-item md-size-100">
                <md-button class="md-primary md-success button-container" @click="updateProfile">
                  Update
                </md-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main main-raised second">
    <div class="section">
      <div v-if="!isLoading" class="container">
        <SearchBar class="top-padding" @send-searchparameter="didChangeSearch" :title="getName() + ' storyspots'"
                   :storyspotCount="'(' + getStoryspots.length + ')'"/>
        <div v-for="story in getStoryspots" v-bind:key="story.id">
          <StoryspotSmall :storyspot="story" :toSpotBasePath="'/storyspots/'"/>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePictureSelector from "../../../../shared/components/ProfilePictureSelector"
import sharedConfig from "../../../../shared/shared-config"
import SearchBar from "../../../../shared/components/SearchBar"
import StoryspotSmall from "../../../../shared/components/cells/StoryspotCell"
import {shallowEqual} from "../../../../shared/utils/general-utils";

export default {
  bodyClass: "Users",
  components: {
    ProfilePictureSelector,
    SearchBar,
    StoryspotSmall
  },
  created() {
    document.title = "User - Admin panel";
  },
  data() {
    return {
      isLoading: true,
      originalUser: null,
      user: null,
      editmode: false,
      newPicture: null,
      removeOriginalImage: null,
      updating: false,
      error: null,
    };
  },
  mounted() {
    this.isLoading = true;
    this.$store.dispatch(sharedConfig.store.actions.ADMIN.GET_SPECIFIC_USER_REQUESTS, this.$route.params.id).then((usr) => {
      this.user = usr;
      this.originalUser = JSON.parse(JSON.stringify(usr));
      this.isLoading = false;
      document.title = this.getName() + " - Admin panel";
    })
  },
  methods: {
    editToggle() {
      this.editmode = !this.editmode;
    },
    getName() {
      if (this.user.fullName) {
        return this.user.fullName;
      } else {
        return this.user.username;
      }
    },
    didChangeSearch(value) {
      this.searchText = value;
    },
    onImageSelected(img) {
      this.setValue(img, 'profileImage');
    },
    onImageDeleted() {
      this.setValue(null, 'profileImage');
    },
    updateProfile() {
      this.updating = true;
      const update = {};
      for (let i = 0; i < Object.keys(this.user).length; i++) {
        const key = Object.keys(this.user)[i];
        const equal = shallowEqual(this.user[key], this.originalUser[key]);
        if (!equal) {
          if (key != "_follows" && key != "_likes" && key != "_followers" && key != "stats") {
            update[key] = this.user[key];
          }
        }
      }
      if (!this.newPicture && this.removeOriginalImage) {
        update["removeImage"] = true;
      } else if (this.newPicture && !this.removeOriginalImage) {
        update["profileImage"] = this.newPicture;
      }
      if (Object.keys(update).length > 0) {
        console.log('object to update', update);
        this.$store
            .dispatch(sharedConfig.store.actions.ADMIN.UPDATE_USER_REQUEST, {
              id: this.user.id,
              data: update
            })
            .then(() => {
              console.log('update success');
              this.editmode = false;
              this.updating = false;
            })
            .catch(e => {
              console.log(e);
              this.error = JSON.stringify(e.data);
              this.updating = false;
            });
      } else {
        console.log('Noting to update')
      }
    },
  },
  computed: {
    getStoryspots() {
      if (!this.user) {
        return [];
      }
      if (this.searchText) {
        return this.$store.getters.getStoryspots.filter(
            (a) =>
                (a.author.id === this.user.id) && a.q.includes(this.searchText.toLowerCase())
        );
      }
      return this.$store.getters.getStoryspots.filter((a) => (a.author.id === this.user.id));
    }
  }
};
</script>

<style lang="scss" scoped>
.second {
  margin-top: 120px;
}
.header {
  margin-bottom: 44px;
}


.image-button-container .md-button {
  width: 100%;
  max-width: 200px;
}

.details {
  margin-top: 45px;
}

textarea {
  display: block;
  padding-left: 0 !important;
  resize: none !important;
}


.section {
  // Used cause we want the create storyspot and search header to act as section "headers"
  padding-top: 20px !important;
}

.main {
  min-height: 80vh;
  z-index: 0;
}

.button-container {
  margin: auto;
  margin-top: 44px;
}

.md-textarea {
  padding-left: 22px !important;
}

.md-field.md-has-textarea:not(.md-autogrow) > .md-icon {
  right: unset;
}

.label-textarea {
  margin-left: 33px;
}

.md-field + .md-has-textarea:not(.md-autogrow) {
  margin-top: 0 !important;
}


</style>
