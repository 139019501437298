<template>
  <div>
    <p v-if="!hasLoaded">Loading...</p>
    <GmapMap
        v-if="hasLoaded && getMarkers != null && getMarkers.length > 0"
        ref="mapRef"
        :center="center"
        :zoom="7"
        style="width: 100%; height: 80vh"
        :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false,
        mapStyle: { styles: mapStyle }
      }"
    >
      <gmap-info-window
          :options="infoOptions"
          :position="infoPosition"
          :opened="infoOpened"
          @closeclick="infoOpened=false">
        <StoryspotSmall v-if="this.selectedSP && this.selectedSP.storyspot" :storyspot="this.selectedSP.storyspot"/>
      </gmap-info-window>
      <gmap-marker
          :key="index"
          v-for="(sp, index) in getMarkers"
          :position="sp.place.position"
          :icon="icon(sp)"
          @click="animateToCords(sp)"
      />
    </GmapMap>
  </div>
</template>

<script>
import * as style from "../assets/googleMapsStyle";
import {gmapApi} from 'vue2-google-maps'
import StoryspotSmall from "../../shared/components/cells/StoryspotCell";

export default {
  name: "MarkerMap",
  components: {
    StoryspotSmall,
  },
  data() {
    return {
      currentPlace: null,
      center: {lat: 59.33459, lng: 18.06324},
      mapStyle: style,
      spList: null,
      selectedSP: null,
      infoPosition: null,
      infoContent: null,
      infoOpened: false,
      infoCurrentKey: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        },
      }
    };
  },
  mounted() {
    this.calculateCenter();
  },
  methods: {
    content() {
      if (this.selectedSP) {
        return `<StoryspotSmall :storyspot="${this.selectedSP.storyspot}"/>`;
      }
      return "";
    },
    icon(sp) {
      return {
        path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
        fillColor:
            sp && sp.storyspot && sp.storyspot.category && sp.storyspot.category.color != null
                ? sp.storyspot.category.color
                : "#269da8",
        fillOpacity: 1.0,
        strokeWeight: 1,
        scale: 0.5,
        strokeColor: "#ffffff"
      };
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    },
    animateToCords(sp) {
      this.selectedSP = sp;
      this.$refs.mapRef.panTo(sp.place.position);
      this.infoPosition = sp.place.position;
      this.infoPosition = sp.place.position;
      this.infoOpened = true;
    },
    calculateCenter() {
      if (this.hasLoaded) {
        const bounds = new this.google.maps.LatLngBounds();
        for (let i = 0; i < this.getMarkers.length; i++) {
          let cords = this.getMarkers[i].place.position;
          bounds.extend(cords);
        }
        this.$refs.mapRef.$mapPromise.then((map) => {
          this.$refs.mapRef.fitBounds(bounds);
        })
      } else {
        setTimeout(() => this.calculateCenter(), 1000);
      }
    },
  },
  computed: {
    google: gmapApi,
    getMarkers() {
      return this.$store.getters.getCurrentUserStoryspotPlaces
    },
    hasLoaded() {
      return this.$store.getters.hasLoadingStoryspotPlaces;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
