export const storyspotSort = (value) => {
    if (value.type === 'date' || value.type === 'number') {
        if (value.value === 'desc') {
            return (a, b) => {
                return a[value.key] > b[value.key] ? -1 : 1;
            }
        } else {
            return (a, b) => {
                return a[value.key] < b[value.key] ? -1 : 1;
            }
        }
    } else {
        return (a, b) => {
            return a[value.key] ? -1 : b[value.key] ? -1 : 1;
        }
    }
}