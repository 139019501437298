<template>
  <div class="main main-raised" style="margin-top: 88px">
    <div class="section">
      <div class="header">
        <h1 class="line">Analytics</h1>
      </div>
      <div class="header">
        <md-icon class="line">bar_chart</md-icon>
        <h4 class="line">Statistics for last {{ diff }} days</h4>
      </div>
      <div v-if="!hasLoaded" class="loader md-layout md-gutter md-alignment-center-center">
        <md-progress-spinner value="center" md-mode="indeterminate"></md-progress-spinner>
      </div>
      <div v-if="hasLoaded && storyspotPlace && stats">
        <div class="md-layout">
          <LineChart class="stats-over-time" :chartData="getUniqCountries.graph"></LineChart>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-100 md-xsmall-size-100">
            <Summary class="stats-over-time" :visitorsGrowth="this.stats.getVisitsGrowth()"
                     :listensGrowth="this.stats.getListensGrowth()"></Summary>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-100 md-xsmall-size-100">
            <BarChart class="stats-over-time" title="Uniq visits by countries"
                      :chartData="getUniqCountries.countries.graph"></BarChart>
          </div>
          <div class="md-layout-item md-size-50 md-small-100 md-xsmall-size-100">
            <BarChart class="stats-over-time" title="Uniq visits by cities"
                      :chartData="getUniqCountries.cities.graph"></BarChart>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-100 md-xsmall-size-100">
            <PieChart class="stats-over-time" :chartData="getDeviceLang.data"></PieChart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {StoryspotStats} from "../../../shared/models/StoryspotStats.model";
import LineChart from "./analytics/LineChart";
import PieChart from "./analytics/PieChart";
import BarChart from "./analytics/BarChart";
import Summary from "./analytics/SummaryByMonth";

export default {
  components: {
    LineChart,
    PieChart,
    Summary,
    BarChart
  },
  data() {
    return {};
  },
  mounted() {
  },
  props: {
    storyspotPlace: {
      type: Object,
      default: null,
    }
  },
  computed: {
    diff() {
      return this.$store.getters.diff;
    },
    settings() {
      return this.$store.getters.getDashboardDays;
    },
    hasLoaded() {
      return this.$store.getters.hasLoadedStats;
    },
    fullStats() {
      return this.$store.getters.dashboardStats;
    },
    getDeviceLang() {
      return this.stats.getDeviceLang(this.settings.fromDate, this.settings.toDate);
    },
    getUniqCountries() {
      return this.stats.getUniqCountries(this.settings.fromDate, this.settings.toDate);
    },
    stats() {
      let uniqL = this.fullStats.uniqStoryspotPlaying.filter((a) => a.storyspotId === this.$route.params.id);
      let uniqV = this.fullStats.uniqVisitsStoryspots.filter((a) => a.storyspotId === this.$route.params.id)
      let stat = new StoryspotStats(uniqL, uniqV);
      return stat;

    },
  }
};
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto 25px auto 0;
  width: fit-content;
}

.header .line:first-child {
  margin: 0 3px 0 0;
}

.header {
  margin: 0 50px 0 50px;
}

.stats-over-time {
  margin: 50px;
}
</style>