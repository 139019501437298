import { render, staticRenderFns } from "./Storyspots.vue?vue&type=template&id=114915e8&scoped=true&"
import script from "./Storyspots.vue?vue&type=script&lang=js&"
export * from "./Storyspots.vue?vue&type=script&lang=js&"
import style0 from "./Storyspots.vue?vue&type=style&index=0&id=114915e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "114915e8",
  null
  
)

export default component.exports