<template>
  <md-card class="card-sum">
    <h3 class="text-center">Month compare</h3>
    <div class="md-layout">
      <div class="md-layout-item md-size-50">
        <h4>Previous month</h4>
        <div class="item">
          <h5 class="start">Listens:</h5>
          <h5><strong>{{ listensGrowth.previous.length }}</strong></h5>
        </div>
        <div class="item">
          <h5>Visitors:</h5>
          <h5><strong>{{ visitorsGrowth.previous.length }}</strong></h5>
        </div>
      </div>
      <div class="md-layout-item md-size-50">
        <h4>This month</h4>
        <div class="item">
          <h5 class="start">Listens:</h5>
          <h5><strong>{{ listensGrowth.current.length }}</strong></h5>
          <md-icon v-if="!visitorsGrowth.increase" class="down">trending_down</md-icon>
          <md-icon v-if="visitorsGrowth.increase" class="up">trending_up</md-icon>

        </div>
        <div class="item">
          <h5>Visitors:</h5>
          <h5><strong>{{ visitorsGrowth.current.length }}</strong></h5>
          <md-icon v-if="!listensGrowth.increase" class="down">trending_down</md-icon>
          <md-icon v-if="listensGrowth.increase" class="up">trending_up</md-icon>
        </div>
      </div>
    </div>
  </md-card>
</template>
<script>
export default {
  name: 'Stat-basic-summary',
  components: {},
  data() {
    return {};
  },
  props: {
    visitorsGrowth: {
      type: Object,
      default: null,
    },
    listensGrowth: {
      type: Object,
      default: null,
    }
  }
}
</script>
<style scoped>
.down {
  color: red !important;
}

.up {
  color: green !important;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item strong {
  padding-left: 8px;
}

.card-sum {
  padding: 0 50px 50px 50px;
}

p.start {
  text-align: justify;
  display: inline;
}

h1.start {
  margin: 0;
  display: inline-block;
}
</style>