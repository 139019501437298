<template>
  <md-card class="card-sum">
    <h5 class="text-center">{{ headline }}</h5>
    <p class="text-center small-paragraph">{{ subheadline }}</p>
    <h5 class="text-center"><strong>{{ number }}</strong></h5>
  </md-card>
</template>
<script>
export default {
  name: 'Stat-total-summary',
  components: {},
  data() {
    return {};
  },
  props: {
    headline: {
      type: String,
      default: '',
    },
    subheadline: {
      type: String,
      default: '',
    },
    number: {
      type: Number,
      default: 0,
    },
  }
}
</script>
<style scoped>
h5 {
  margin-bottom: 0 !important;
}
.small-paragraph {
  min-height: 20px;
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.item strong {
  padding-left: 8px;
}

.card-sum {
  padding: 0 50px 50px 50px;
  height: 145px;
}
</style>