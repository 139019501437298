<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-50 md-small-size-100 md-small-size-100 md-medium-size-50">
        <div class="no-image">
          <label v-if="!getMainImg">No image</label>
          <div v-if="getMainImg">
            <a class="thumbnail-image-preview" href="#thumb" @click="removeFile(0)">
              <img
                  :key="'main-image'"
                  v-if="getMainImg"
                  :id="'Image' + 1"
                  :alt="'main-thumb-image'"
                  :src="getMainImg"
                  width="150"
                  height="150"
              />
              <span>
                <img class="image-preview" :src="getMainImg" :alt="'main-image'"/>
            </span>
            </a>
            <div class="edit-buttons">
            <!--  <a class="icon-button" @click="doCropImage(0)">
                <md-icon class="icon-button-edit">crop</md-icon>
              </a> -->
              <a class="icon-button" @click="removeFile(0)">
                <md-icon class="icon-button-delete">delete</md-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="md-layout-item md-size-50 md-small-size-100 md-small-size-100 md-medium-size-50">
        <div class="image-button-container">
          <input
              type="file"
              id="imageFile"
              ref="images"
              @change="selectFile"
              accept="image/*"
              multiple
              hidden
          />
          <md-button
              class="md-primary md-success"
              @click="triggerSelect">
            Add images
          </md-button>
        </div>
      </div>
    </div>
    <div class="md-layout">
      <div class="flexible flexible-row">
        <div
            v-for="(file, index) in getImages"
            v-bind:key="index"
            class="no-image-small"
        >
          <div class="break-row" v-if="index === 5"/>
          <a class="thumbnail-image-preview" href="#thumb">
            <img
                :key="'Image' + index"
                v-if="file"
                :id="'Image' + index"
                :src="file"
                :alt="'image' + index"
                width="100"
                height="100"
            />

            <span>
                <img class="image-preview" :src="file" :alt="'thumb-' + index"/>
            </span>
          </a>
          <div class="edit-buttons">
           <!-- <a class="icon-button" @click="doCropImage(index+1)">
              <md-icon class="icon-button-edit">crop</md-icon>
            </a> -->
            <a class="icon-button" @click="removeFile(index+1)">
              <md-icon class="icon-button-delete">delete</md-icon>
            </a>
          </div>
        </div>
        <div
            v-for="index in 7 - getImages.length"
            v-bind:key="'e' + index"
            class="no-image-small"
        >
          <label>+</label>
        </div>
      </div>
    </div>
    <modal v-if="showCropper" @close="closeCropper">
      <template slot="header">
        <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="closeCropper"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <cropper
            v-if="getCropperImage"
            class="cropper"
            ref="cropper"
            :src="getCropperImage"
            :stencil-props="{
		           aspectRatio: 1
	          }"
        />
        <md-button class="md-primary" @click="saveCrop">Save</md-button>
      </template>
    </modal>
  </div>
</template>
<script>
import {Cropper} from 'vue-advanced-cropper'
import Modal from "./Modal";
import {cropImage} from "../utils/image-utils";

export default {
  components: {
    Cropper,
    Modal
  },
  props: {
    setImages: {type: Function},
    updateImage: {type: Function},
    value: {type: Array},
  },
  data() {
    return {
      showCropper: false,
      cropperImage: null,
      story: {
        images: [],
      },
    };
  },
  methods: {
    closeCropper() {
      this.cropperImage = null;
      this.showCropper = false;
    },
    triggerSelect() {
      document.getElementById("imageFile").click();
    },
    selectFile() {
      const files = Array.from(this.$refs.images["files"]);
      if (this.value?.length + files.length > 8) {
        this.error = "A maximum of 8 images is allowed.";
      } else {
        this.error = "";
        this.setImages(files);
      }
    },
    removeFile(index) {
      const removed = this.value[index].id;
      this.value.splice(index, 1);
      this.setImages([], [removed]);
    },
    doCropImage(index) {
      this.cropperImage = {
        index: index,
        image: this.value[index],
      }
      this.showCropper = true;
    },
    async saveCrop() {
      if (this.cropperImage && this.cropperImage.image) {
        const {
          coordinates, ig, visibleArea, canvas
        } = this.$refs.cropper.getResult();
        const config = {
          file: this.cropperImage.image,
          // Resizing on the way out instead.
          maxSize: 9999,
          width: coordinates.width,
          height: coordinates.height,
          x: coordinates.left,
          y: coordinates.top,
        };
        let img = await cropImage(config);
        let indexCopy = JSON.parse(JSON.stringify(this.cropperImage.index));
        this.cropperImage = null;
        this.showCropper = false;
        this.updateImage(indexCopy, {id: null, file: img});
        this.value[indexCopy] = {id: null, file: img};
      }
    }
  },
  computed: {
    getMainImg() {
      if (this.value && this.value.length > 0) {
        let resp = Array.from(this.value).map(img => {
          if (typeof img === 'string') {
            return img;
          } else if (img && img.file && img.file.url) {
            return img.file.url;
          } else if (img && img.url) {
            return img.url;
          } else if (img && img.file) {
            return URL.createObjectURL(img.file);
          } else {
            return URL.createObjectURL(img);
          }
        })[0];
        return resp;
      } else {
        return null;
      }
    },
    getImages() {
      if (this.value) {
        return Array.from(this.value).map(img => {
          if (typeof img === 'string') {
            return img;
          } else if (img && img.file && img.file.url) {
            return img.file.url;
          } else if (img && img.url) {
            return img.url;
          } else if (img && img.file) {
            return URL.createObjectURL(img.file);
          } else {
            return URL.createObjectURL(img);
          }
        }).slice(1, Array.from(this.value).length > 8 ? 8 : Array.from(this.value).length);
      } else {
        return [];
      }
    },
    getCropperImage() {
      if (this.cropperImage && this.cropperImage.image) {
        if (typeof this.cropperImage.image === 'string') {
          return this.cropperImage.image;
        } else if (this.cropperImage.image && this.cropperImage.image.file && this.cropperImage.image.file.url) {
          return this.cropperImage.image.file.url;
        } else if (this.cropperImage.image && this.cropperImage.image.url) {
          return this.cropperImage.image.url;
        } else if (this.cropperImage.image && this.cropperImage.image.file) {
          return URL.createObjectURL(this.cropperImage.image.file);
        } else {
          return URL.createObjectURL(this.cropperImage.image);
        }
      } else
        return null;
    },
  }
}
</script>
<style lang="scss" scoped>
img {
  object-fit: contain;
}

.no-image {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dotted lightgrey;
  border-radius: 5px;
  min-height: 150px;
  min-width: 150px;
  position: relative;

  label {
    color: lightgrey;
    width: 50%;
    font-weight: bold;
    font-size: 1.25rem;
    text-align: center;
  }
}

.flexible {
  display: flex;
  flex-wrap: wrap;
}

.flexible-row {
  flex-direction: row;
}

.break-row {
  flex-basis: 100%;
  height: 0;
}

.no-image-small {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dotted lightgrey;
  border-radius: 5px;
  min-height: 100px;
  max-height: 100px;
  min-width: 100px;
  max-width: 100px;
  margin-top: 8px;
  position: relative;

  label {
    color: lightgrey;
    text-align: center;
    font-weight: bold;
    font-size: 1.25rem;
  }
}

.no-image-small:not(:first-child),
.no-image-small:not(:nth-child(n + 5)) {
  margin-left: 10px;
}

.thumbnail-image-preview {
  position: relative;
  z-index: 0;
}

.thumbnail-image-preview:hover {
  background-color: transparent;
  z-index: 50;
}

.thumbnail-image-preview span {
  /*CSS for enlarged image*/
  position: absolute;
  background-color: white;
  padding: 5px;
  right: -1000px;
  border: 1px solid darkgray;
  visibility: hidden;
  color: black;
  text-decoration: none;
  width: 33vw;
}

.thumbnail-image-preview span img {
  /*CSS for enlarged image*/
  border-width: 0;
  padding: 2px;
}

.thumbnail-image-preview:hover span {
  /*CSS for enlarged image on hover*/
  visibility: visible;
  top: 0;
  right: -100px; /*position where enlarged image should offset horizontally */
}

.edit-buttons {
  position: absolute;
  right: 0 !important;
  bottom: 0 !important;
}

.icon-button {
  cursor: pointer;
  z-index: 99999 !important;
}
.icon-button-edit {
  color: #2abbc9 !important;
}
.icon-button-delete {
  color: #e74c3c !important;
}
</style>