import sharedConfig from "../../../shared/shared-config";
import baseAPI from "../../../shared/services/base-api-service"
import {RecommendedLocation} from "../models/RecommendedLocation.model";
import {RecommendedExplore} from "../models/RecommendedExplore.model";

const state = {
    explore: [],
    locations: [],
    isLoadingRecommendations: false,
    hasLoadedRecommendations: false,
}

const getters = {
    getRecommendedExplore(state) {
        return state.explore;
    },
    getRecommendedLocations(state) {
        return state.locations;
    },
    isLoadingRecommendations(state) {
        return state.isLoadingRecommendations;
    },
    hasLoadedRecommendations(state) {
        return state.hasLoadedRecommendations;
    }
}

const actions = {
    [sharedConfig.store.actions.STATIC.GET_RECOMMENDED_REQUEST]: ({commit, dispatch}) => {
        commit(sharedConfig.store.actions.STATIC.GET_RECOMMENDED_REQUEST);
        return new Promise(async (resolve, reject) => {
            baseAPI
                .getData(`${sharedConfig.api.recommendationEndpoint}`)
                .then(resp => {
                    commit(sharedConfig.store.actions.STATIC.GET_RECOMMENDED_SUCCESS, resp.data);
                    resolve();
                })
                .catch(e => {
                    commit(sharedConfig.store.actions.STATIC.GET_RECOMMENDED_ERROR, e);
                    reject(e);
                });

        });
    },
}

const mutations = {
    [sharedConfig.store.actions.STATIC.GET_RECOMMENDED_REQUEST]: (state, error) => {
        // TODO. manage error response
        state.isLoadingRecommendations = true;
    },
    [sharedConfig.store.actions.STATIC.GET_RECOMMENDED_SUCCESS]: (state, data) => {
        state.explore = data['topics'].map((a) => new RecommendedExplore(a));
        state.locations = data['locations'].map((a) => new RecommendedLocation(a));
        state.hasLoadedRecommendations = true;
        state.isLoadingRecommendations = false;
    },
    [sharedConfig.store.actions.STATIC.GET_RECOMMENDED_ERROR]: (state, error) => {
        // TODO. manage error response
        state.isLoadingRecommendations = false;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};