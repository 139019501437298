<template>
  <md-card>
    <h3 class="text-center">{{ headline }}</h3>
  <LineChartGenerator
      class="holder"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
  />
  </md-card>
</template>

<script>
import {Line as LineChartGenerator} from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
)

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        transitions: {
          show: {
            animations: {
              x: {
                from: 0
              },
              y: {
                from: 0
              }
            }
          },
          hide: {
            animations: {
              x: {
                to: 0
              },
              y: {
                to: 0
              }
            }
          },
        },
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            loop: false
          }
        },
        scales: {
          y: {
            min: 0,
            title: {display: true, text: this.yheadline, padding: 10},
            ticks: {
              beginAtZero: true,
              callback: (value, index, values) => {
                return Number.isInteger(value) ? value : null;
              }
            }
          },
        },
        plugins: {
          legend: {
            labels: {
              family: 'Raleyway'
            }
          },
        }
      },
    }
  },
  props: {
    headline: {
      type: String,
      default: 'Uniq visits'
    },
    yheadline: {
      type: String,
      default: ''
    },
    chartData: {
      type: Object,
      required: true,
    },
    /* chartOptions: {
       type: Object,
       required: true,
     }, */
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
      }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
}
</script>
<style>
.holder {
  padding: 50px ;
}
</style>
