import { LatLng } from "./LatLng.model";

export class Place {
    constructor(json) {
        this.id = json.id;
        this.storyspots = json.storyspots;
        this.placeId = json.placeId;
        this.idStr = json.idStr;
        this.phone = json.phone;
        this.name = json.name;
        this.q = json.q;
        this.createdAt = json.createdAt;
        this.updatedAt = json.updatedAt;
        this.position = new LatLng(json.position);
    }
}
