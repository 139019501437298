<template>
  <div>
    <div>
      <div class="md-layout md-gutter">
        <div
            class="md-layout-item md-size-70 user-text-container"
        >
          <div class="text-container">
            <div class="text-left">
              <h4 class="title">
                <router-link :to="'/storyspotters/' + storyspotter.id">
                  <span class="tim-note">{{ storyspotter.name }}</span>
                </router-link>
              </h4>
              <p>{{storyspotter.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <li class="dropdown-divider"></li>
  </div>
</template>
<script>

export default {
  components: {},
  props: {
    storyspotter: {
      type: Object,
      default: null
    },
  },
};
</script>
<style lang="scss" scoped>
</style>