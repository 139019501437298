<template>
  <div>
    <div>
      <div class="md-layout md-gutter">
        <div
            class="md-layout-item md-size-70 user-text-container"
        >
          <div class="text-container">
            <div class="text-left">
              <h3 class="title">
                <router-link :to="'/recommendations/explore/' + explore.id">
                  <span class="tim-note">{{ explore.name }}</span>
                </router-link>
              </h3>
              <p>Categories: <strong>{{selectedCategories.toString()}}</strong></p>
              <p>Languages: <strong>{{selectedLanguages.toString()}}</strong></p>
              <p>Search text: <strong>{{explore.searchText}}</strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <li class="dropdown-divider"></li>
  </div>
</template>
<script>
import Badge from "../../../../shared/components/Badge";
import QrCode from "../../../../shared/components/QrCode";

export default {
  components: {
    QrCode,
    Badge
  },
  props: {
    explore: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      searchInput: null,
      showQrModal: false,
    }
  },
  mounted() {
  },
  methods: {
    showModal() {
      this.showQrModal = true;
    },
    closeModal() {
      this.showQrModal = false;
    },
  },
  computed: {
    selectedCategories() {
      return this.$store.getters.getCategories.filter((a) => this.explore.categoryFilter.includes(a.id)).map((a) => a.name);
    },
    selectedLanguages() {
      return this.$store.getters.getLanguages.filter((a) => this.explore.languageFilter.includes(a.id)).map((a) => a.name);
    }
  }
};
</script>
<style lang="scss" scoped>

.wrapper {
  margin-top: 28px;
}

.blockquote {
  font-size: 18px;
}

.blockquote strong {
  margin: 0 5px 0 5px;
}

.text-container {
  float: left;
  margin-top: 0;
  padding: 0;
}

.text-container .p {
  text-overflow: ellipsis;
}

.title {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  color: #3C4858 !important;
}

.md-theme-default a:not(.md-button) {
  color: unset !important;
}


.center {
  margin: auto;
  width: 50%;
}

.icon {
  font-size: 18px !important;
}

.text-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.play-icon {
  position: absolute !important;
  right: 30px;
}


.dropdown-item:hover {
  .md-icon {
    color: white !important;
  }

  p {
    color: white !important;
  }
}

.md-tooltip {
  border-radius: unset;
  color: white !important;
  background-color: #989898 !important;
  font-weight: unset;
  box-shadow: unset;
  max-width: unset;
}

.not-active {
  float: left;
  padding-right: 8px
}

.displayImage {
  object-fit: contain !important;
  // Just to fill width
  width: 100px;
}

.no-profile-pic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.no-profile-pic {
  font-size: 68px !important;
  opacity: 0.5;
}

.md-list-item a .material-icons, .md-list-item a .md-icon, .md-list-item a .fab, .md-list-item a .fa {
  font-size: 25px !important;
}

.mini-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  p {
    padding-top: 10px;
  }
}

.user-text-container {
  min-height: 100px;

}
.dot {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: inline-block;
}
</style>