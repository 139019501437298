import {
    comparePreviousVScurrent,
    getCitiesAndCountries,
    getEventsOverTimeByDay,
    getMonthTimeRange,
    getToday, graphColors,
    lineChart, lineChartv2
} from "../utils/stat-util";
const locale = require('locale-codes')
export class StoryspotStats {

    constructor(uniqPlaying, uniqVisits) {
        this.uniqStoryspotPlaying = uniqPlaying;
        this.uniqVisitsStoryspots = uniqVisits;
    }

    getDeviceLang(fromDate, toDate) {
        let total = this.uniqVisitsStoryspots.concat(this.uniqStoryspotPlaying).filter((a) => a.date > fromDate && a.date < toDate);
        let res = {};
        for (let i = 0; i < total.length; i++) {
            res[total[i].uniqUserId] = total[i]['userLanguage'];
        }
        let values = {};
        for (let i = 0; i < Object.values(res).length; i++) {
            let code = Object.values(res)[i];
            try {
                let loc = locale.getByTag(code);
                if (loc) {
                    code = loc.name;
                }
            }  catch (e) {
                console.log('no transcode')
            }

            if (!(code in values)) {
                values[code] = 1;
            } else {
                values[code] = values[code] + 1;
            }
        }

        let response = {
            value: values,
            data: {
                labels: Object.keys(values),
                datasets: [
                    {
                        label: '',
                        backgroundColor: graphColors,
                        data: Object.values(values)
                    }],

            },
        };
        return response;
    }


    getListensGrowth() {
        let currentDate = new Date();
        let previousMonth = new Date();
        previousMonth.setMonth(currentDate.getMonth() - 1);

        let currentMonthTimeRange = getMonthTimeRange(currentDate);
        let previousMonthTimeRange = getMonthTimeRange(previousMonth);

        let previous = this.uniqStoryspotPlaying.filter((a) => a.date > previousMonthTimeRange.start && a.date < previousMonthTimeRange.end);
        let current = this.uniqStoryspotPlaying.filter((a) => a.date > currentMonthTimeRange.start && a.date < currentMonthTimeRange.end);
        let today = this.uniqStoryspotPlaying.filter((a) => a.date > getToday().start && a.date < getToday().end);

        let {diff, increase} = comparePreviousVScurrent(current, previous);


        return {
            previous: previous,
            current: current,
            today: today,
            diff: diff,
            increase: increase,
        }
    }

    getVisitsGrowth() {
        let currentDate = new Date();
        let previousMonth = new Date();
        previousMonth.setMonth(currentDate.getMonth() - 1);

        let currentMonthTimeRange = getMonthTimeRange(currentDate);
        let previousMonthTimeRange = getMonthTimeRange(previousMonth);

        let previous = this.uniqVisitsStoryspots.filter((a) => a.date > previousMonthTimeRange.start && a.date < previousMonthTimeRange.end);
        let current = this.uniqVisitsStoryspots.filter((a) => a.date > currentMonthTimeRange.start && a.date < currentMonthTimeRange.end);
        let today = this.uniqVisitsStoryspots.filter((a) => a.date > getToday().start && a.date < getToday().end);

        let {diff, increase} = comparePreviousVScurrent(current, previous);

        return {
            previous: previous,
            current: current,
            today: today,
            diff: diff,
            increase: increase,
        }
    }

    // Horrible functions, quickly to get a prototype up.
  //  getAuthorVisits(fromDate, toDate) {
   //     return this.uniqAuthorVisits.filter((a) => a.date > fromDate && a.date < toDate);
   // }

    getStoryspotVisits(fromDate, toDate) {
        return this.uniqVisitsStoryspots.filter((a) => a.date > fromDate && a.date < toDate);
    }

    getStoryspotListens(fromDate, toDate) {
        return this.uniqVisitsStoryspots.filter((a) => a.date > fromDate && a.date < toDate);
    }

    getUniqCountries(fromDate, toDate) {
        let countries = {};
        let cities = {};

        getCitiesAndCountries(this.uniqStoryspotPlaying.filter((a) => a.date > fromDate && a.date < toDate), cities, countries);
        getCitiesAndCountries(this.uniqVisitsStoryspots.filter((a) => a.date > fromDate && a.date < toDate), cities, countries);

        cities = Object.entries(cities)
            .sort(([, a], [, b]) => b - a)
            .reduce((r, [k, v]) => ({...r, [k]: v}), {});
        countries = Object.entries(countries)
            .sort(([, a], [, b]) => b - a)
            .reduce((r, [k, v]) => ({...r, [k]: v}), {});

        let graphValuesUSP = getEventsOverTimeByDay(this.uniqStoryspotPlaying, fromDate, toDate);
        let graphValuesUVS = getEventsOverTimeByDay(this.uniqVisitsStoryspots, fromDate, toDate);

        let dataSets = [
            {
                label: 'Storyspot listens',
                data: graphValuesUSP.values,
                borderColor: graphColors[1],
                backgroundColor: graphColors[1],
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 12,
                pointHitRadius: 12,
                tension: 0.4
            },
            {
                label: 'Storyspot page',
                data: graphValuesUVS.values,
                borderColor: graphColors[2],
                backgroundColor: graphColors[2],
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 12,
                pointHitRadius: 12,
                tension: 0.4
            }
        ];
        let labels  = graphValuesUSP.keys.map((a) => {
            const dateSet = a.toDateString().split(' ');
            return `${a.toLocaleString('en-us', {month: 'long'})} ${dateSet[2]}`;
        });
        let graph = lineChartv2(labels, dataSets);

        return {
            totalCountries: Object.keys(countries).length,
            totalCities: Object.keys(cities).length,
            cities: {
                value: cities,
                graph: {
                    labels: Object.keys(cities),
                    datasets: [
                        {
                            data: Object.values(cities),
                            borderWidth: 2.5,
                            borderRadius: 8,
                            backgroundColor: graphColors.map((a) => a = a + 'CC'),
                            borderColor: graphColors,
                        }
                    ]
                }
            },
            countries: {
                value: countries,
                graph: {
                    labels: Object.keys(countries),
                    datasets: [
                        {
                            data: Object.values(countries),
                            borderWidth: 2.5,
                            borderRadius: 8,
                            backgroundColor: graphColors.map((a) => a = a + 'CC'),
                            borderColor: graphColors,
                        }
                    ]
                }
            },
            graph: graph,
        }
    }
}